import { colors, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '50%',
  }
}));

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
})(Tooltip);

const CircleGraphItem = ({ value, color, min, max, basis, borderColor }) => {
  const classes = useStyles()
  if (value === null) return <span style={{color: colors.grey[400]}}>✕</span>
  value = +value
  const itemSize = [10, basis]
  const relVal = (value - min) / (max - min)
  const size = itemSize[0] + ((itemSize[1] - itemSize[0]) * relVal)

  return (
    <StyledTooltip title={value} arrow placement="bottom">
      <div className={classes.root} style={{
        width: size,
        height: size,
        background: color,
        border: `1px solid ${borderColor}`
      }}>
      </div>
    </StyledTooltip>
  )
}

export default CircleGraphItem