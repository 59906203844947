import { Dialog } from '@material-ui/core';
import React from 'react';

import QrReader from 'react-qr-reader'

const QrCodeScanner = ({  onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const handleError = error => {
    if (`${error}` === 'NotAllowedError: Permission denied') {
      error = 'Bitte Zugriffsrechte auf die Kamera überprüfen.'
    }
    onClose({error})
  }
  const handleScan = qrCode => {
    if (!qrCode) {
      return;
    }
    if (!qrCode.match(/^[a-z0-9-]+$/)) {
      return onClose({error: 'QR-Code enthält nicht unterstützte Zeichen.'})
    }
    onClose({qrCode})
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="events-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xs"
    >
      <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
    </Dialog>
  );
}

export default QrCodeScanner