const BASE_PATH = 'api/context/'

const types = {
  SET_COURSE: BASE_PATH + 'SET_COURSE',
  SET_ROLE: BASE_PATH + 'SET_ROLE',
  SET_ROOM: BASE_PATH + 'SET_ROOM',
  SET_SP_ROOM: BASE_PATH + 'SET_SP_ROOM',
  SET_GROUP: BASE_PATH + 'SET_GROUP',
  ADD_STUDENT: BASE_PATH + 'ADD_STUDENT',
  REMOVE_STUDENT: BASE_PATH + 'REMOVE_STUDENT',
}

export default types
