import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';

import config from '../../config';
import { createLoginToken, getLoginToken } from '../../ducks/auth/api';
import { userActions, userOperations } from '../../ducks/users';

const appUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL

const useStyles = makeStyles(theme => ({
  button: {
    width: 250,
    height: 55,
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  dialog: {
    textAlign: 'center'
  }
}))

const QrCodeLogin = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loginToken, setLoginToken] = useState(null)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (open) {
      if (!loginToken) {
        setError(null)
        createLoginToken()
          .then(token => setLoginToken(token))
          .catch(error => setError(error))
      }
    } else {
      setLoginToken(null)
      setError(null)
      setCount(0)
    }
  }, [loginToken, open])

  useEffect(() => {
    if (loginToken && open) {
      getLoginToken(loginToken.token)
        .then(token => {
          if (token.accessToken) {
            localStorage.removeItem(`${config.localStoragePrefix}location`)
            dispatch(userActions.setIntendedUrl('/learnable-moments'))
            dispatch(userOperations.authenticateWithAccessToken(token.accessToken))
          } else {
            setTimeout(() => {
              setCount(count + 1)
            }, 5000);
          }
        })
        .catch(error => setError(error))
    }
  }, [loginToken, open, count, dispatch])


  const handleClose = result => {
    setOpen(false);
  }

  const dialogContent = error ?
    `Es ist ein Fehler aufgetreten: ${error}` :
    loginToken ?
      <>
        <QRCode size={250} value={`${appUrl}authenticate-token/${loginToken.token}`}/><br/>
        <small>
          Dieser QR-Code ist gültig bis {moment(loginToken.validUntil).format('HH:mm:ss')} Uhr.
          {/* <br/>(Count: {count}, URL: {`${appUrl}/authenticate-token/${loginToken.token}`}) */}
        </small>
      </> :
      'Daten werden geladen...'

  return <>
    <Button
      variant="outlined"
      className={classes.button}
      title="Anmelden via QR-Code"
      onClick={() => setOpen(true)}
    >
      Anmelden via QR-Code
    </Button>
    <Dialog
      className={classes.dialog}
      onClose={handleClose}
      aria-labelledby="events-dialog-title"
      open={open}
      fullWidth={false}
      maxWidth="xs"
    >
      <DialogTitle>QR-Code scannen zum Anmelden</DialogTitle>
      <DialogContent>
        { dialogContent }
      </DialogContent>
    </Dialog>
  </>
}

export default QrCodeLogin
