import { Button, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { actionItemType } from '../ducks/learnableMoments';
import {
  update as updateLearnableMoment
} from '../ducks/learnableMoments/api';

const styles = makeStyles({
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  content: {
    maxHeight: '200px',
    overflow: 'auto',
    marginTop: '10px'
  }
})

const getLearningGoalItem = learnableMoment => learnableMoment.actionItems?.find(actionItem => actionItem.type === actionItemType.LEARNING_GOAL)
const getLearningGoal = learnableMoment => getLearningGoalItem(learnableMoment)?.content || ''

const LearningGoal = ({learnableMoment, editable}) => {
  const classes = styles()
  const [learningGoal, setLearningGoal] = useState(getLearningGoal(learnableMoment))
  const [error, setError] = useState(null)
  const [edit, setEdit] = useState(learningGoal === '')
  const [dirty, setDirty] = useState(false)
  const user = useSelector(state => state.user.user)

  const handleLearningGoalChange = event => {
    setLearningGoal(event.target.value)
    setDirty(true)
  }
  const abortEdit = () => {
    setLearningGoal(getLearningGoal(learnableMoment))
    setDirty(false)
    setEdit(false)
  }
  const saveLearningGoal = () => {
    const learningGoalActionItem = getLearningGoalItem(learnableMoment) || { type: actionItemType.LEARNING_GOAL }
    learningGoalActionItem.content = learningGoal
    updateLearnableMoment(user, {
      ...learnableMoment,
      actionItems: [
        ...learnableMoment.actionItems.filter(actionItem => actionItem.type !== actionItemType.LEARNING_GOAL),
        learningGoalActionItem
      ]})
      .then(updatedLearnableMoment => {
        setLearningGoal(getLearningGoal(updatedLearnableMoment))
        setDirty(false)
        setEdit(false)
      })
      .catch(error => setError(error.message || 'Unbekannter Fehler'))
  }

  if (!editable && learningGoal === '') {
    return null
  }
  return <>
    <Typography variant='h3' className={classes.title}>Vorsätze für das nächste Mal</Typography>
    { editable && edit ? <>
      <TextField
          style={{width: '100%', marginTop: '20px', marginBottom: '20px'}}
          label='Vorsätze eintragen / ändern'
          multiline
          minRows={4}
          maxRows={10}
          variant='outlined'
          value={learningGoal}
          onChange={handleLearningGoalChange}
        />
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button variant="contained" onClick={() => abortEdit()}>Abbrechen</Button>
          </Grid>
          <Grid item>
            {error}
            <Button variant="contained" color="primary" disabled={!dirty} onClick={() => saveLearningGoal()}><DoneIcon/>&nbsp;Speichern</Button>
          </Grid>
        </Grid>
      </> : <>
      <Grid container>
        <Grid item>
          <Typography variant='body1' className={classes.content}>{ learningGoal.replace(/\n/g, ' ↵ ') }</Typography>
        </Grid>
        { editable &&
          <Grid item>
            <Tooltip title="Vorsätze bearbeiten">
              <IconButton size="small" color="primary" onClick={() => setEdit(true)}><EditIcon/></IconButton>
            </Tooltip>
          </Grid>
        }
      </Grid>
      </>
    }
  </>
}


export default LearningGoal