import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { userActions } from '../../ducks/users';

const useStyles = makeStyles(_ => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  }
}))

const RequireAuth = ({ permissions, children }) => {
  const user = useSelector(state => state.user.user)
  const {pathname, search} = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  if (!user) {
    dispatch(userActions.setIntendedUrl({pathname, search}))
    return <Redirect to="/login"/>
  }

  const canAccessPrivateRoute = user && user.permissions && !!user.permissions.filter(userPermission => permissions.includes(userPermission)).length
  if (!canAccessPrivateRoute) {
    return <Redirect to="/403" />
  }
  return (
    <div className={classes.wrapper}>
      {children}
    </div>
  )
}

export default RequireAuth
