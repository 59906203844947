import fetch from '../fetch';

const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const create = (user, learnableMomentData) => {
  const url = `${mongoUrl}/learnable-moments`
  return  fetch(url, {
    method: 'POST',
    body: JSON.stringify(learnableMomentData),
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const read = (user, id) => {
  const url = `${mongoUrl}/learnable-moments/${id}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const update = (user, data) => {
  const url = `${mongoUrl}/learnable-moments/${data.id}`
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const remove = (user, data) => {
  const url = `${mongoUrl}/learnable-moments/${data.id}`
  return fetch(url, {
    method: 'DELETE',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const list = (user, {room, onlyActive, onlyFinished, withStubs, timeFrom, timeTo, student, onlyIfScanned}) => {
  const filter = {}
  if (room) {
    filter.room = room.id
  }
  if (student) {
    filter.student = student.id
  }
  if (onlyActive) {
    filter.active = true
  }
  if (onlyFinished) {
    filter.active = false
  }
  if (withStubs) {
    filter.withStubs = true
  }
  if (onlyIfScanned) {
    filter.onlyIfScanned = true
  }
  if (timeFrom) { filter.timeFrom = timeFrom }
  if (timeTo) { filter.timeTo = timeTo }
  const query = Object.entries(filter).map(([k, v]) => `${k}=${v}`).join('&')
  const url = `${mongoUrl}/learnable-moments?${query}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const getSubmissions = (user, id) => {
  const url = `${mongoUrl}/submissions?learnable_moment_id=${id}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const countByPropertyAndIds = (user, type, ids) => {
  if (!type || !ids) {
    return;
  }
  const url = `${mongoUrl}/count-learnable-moments?type=${type}&ids=${ids.join(',')}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  if (!response.ok) throw new Error(response.statusText)
  if (response.status === 204) return null
  return response.json()
}

const api = {
  create,
  read,
  update,
  remove,
  list,
  getSubmissions,
  countByPropertyAndIds,
}

export default api