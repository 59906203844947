import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useState } from 'react';

import { rooms } from '../ducks/context';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '120px'
  }
}))

export default function RoomSelect ({ selectedRoom, filterByRole, onRoomSelect, title, ...rest }) {
  const classes = useStyles()
  const [value, setValue] = useState(selectedRoom?.id || '')
  const handleChange = event => {
    const roomId = event.target.value
    setValue(roomId)
    onRoomSelect(rooms.find(room => room.id === roomId))
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} {...rest}>
      <InputLabel id="room-label">{title || 'Raum'}</InputLabel>
      <Select
        labelId="room-label"
        value={value}
        onChange={handleChange}
        label={title || 'Raum'}
      >
        { rooms
          .filter(room => !filterByRole || room.roles.includes(filterByRole))
          .map(room => <MenuItem key={room.id} value={room.id}>{room.title}</MenuItem>)
        }
      </Select>
  </FormControl>
  )
}