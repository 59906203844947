import types from './types'

export const setCourse = course => ({
  type: types.SET_COURSE,
  payload: course
})
export const setRole = role => ({
  type: types.SET_ROLE,
  payload: role
})
export const setRoom = room => ({
  type: types.SET_ROOM,
  payload: room
})
export const setSpRoom = room => ({
  type: types.SET_SP_ROOM,
  payload: room
})
export const setGroup = group => ({
  type: types.SET_GROUP,
  payload: group
})
export const addStudent = student => ({
  type: types.ADD_STUDENT,
  payload: student
})
export const removeStudent = student => ({
  type: types.REMOVE_STUDENT,
  payload: student.id
})

const actions = {
  setCourse,
  setRole,
  setRoom,
  setSpRoom,
  setGroup,
  addStudent,
  removeStudent,
}
export default actions