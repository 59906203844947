import { Button } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Camera';
import React, { useState } from 'react';

import QrCodeScanner from '../commons/QrCodeScanner';

export default function ScanQrCodeButton ({onSuccess, onError}) {
  const [open, setOpen] = useState(false)
  const handleClose = result => {
    setOpen(false);
    if (result) {
      if (result.error) {
        onError(result.error)
      }
      if (result.qrCode) {
        onSuccess(result.qrCode)
      }
    }
  }
  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}><CameraIcon/>&nbsp;QR-Code scannen</Button>
      <QrCodeScanner open={open} onClose={handleClose}/>
    </>
  )
}