import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { purple } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';

import { AgencyComparisonSlider, CommunionComparisonSlider, EpaComparisonSlider, InterpersonalResilienceComparisonSlider } from '../commons/ComparisonSlider';
import InfoDialog, { AgencyContent, CommunionContent, InterpersonalResilienceContent } from '../commons/InfoDialog';
import { colors } from '../config';
import { observationRole } from '../ducks/learnableMoments';
import LearningGoal from './LearningGoal';


const ObservationHeader = ({submissions}) => {
  if (!submissions) {
    return 'Keine Beobachtung erfasst.'
  }

  const socialCompetencies = valuesForComparison(submissions, ['agency', 'communion', 'interpersonal-resilience', 'n-epa'])

  return <>
    <Comparison values={socialCompetencies.agency} title="Agency" Chip={AgencyChip}/>&nbsp;
    <Comparison values={socialCompetencies.communion} title="Communion" Chip={CommunionChip}/>&nbsp;
    <Comparison values={socialCompetencies['interpersonal-resilience']} title="Interpersonelle Resilienz" Chip={InterpersonalResilienceChip}/>&nbsp;
    <Comparison values={socialCompetencies['n-epa']} title="nEPA" Chip={EpaChip}/>
  </>
}

const observationContentStyles = makeStyles(theme => ({
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  content: {
    overflow: 'auto'
  },
  contentFooter: {
    marginTop: '10px',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'flex-end',
    },
  }
}))

const ObservationContent = ({submissions, lm, own}) => {
  const classes = observationContentStyles()
  const groupSubmission = submissions[observationRole.GROUP][0];
  const quantitativeValues = valuesForComparison(submissions, ['agency', 'communion', 'interpersonal-resilience', 'n-epa'])
  const items = [
    {
      title: 'Agency',
      qualitativeField: 'agency-text',
      quantitativeField: 'agency',
      HelpContent: AgencyContent,
      ComparisonSlider: AgencyComparisonSlider,
    },
    {
      title: 'Communion',
      qualitativeField: 'communion-text',
      quantitativeField: 'communion',
      HelpContent: CommunionContent,
      ComparisonSlider: CommunionComparisonSlider,
    },
    {
      title: 'Interpersonelle Resilienz',
      qualitativeField: 'interpersonal-resilience-text',
      quantitativeField: 'interpersonal-resilience',
      HelpContent: InterpersonalResilienceContent,
      ComparisonSlider: InterpersonalResilienceComparisonSlider,
    },
    {title: 'Was genauso machen?', qualitativeField: 'learning-goal-a'},
    {title: 'Was vermeiden?', qualitativeField: 'learning-goal-b'},
    {title: 'Was anders machen?', qualitativeField: 'learning-goal-c'},
    {
      title: lm.assessmentItems.find(item => item.type === 'n-epa')?.title?.replace(/^/, 'nEPA: ') || 'nEPA',
      quantitativeField: 'n-epa',
      ComparisonSlider: EpaComparisonSlider,
    },
  ]
    .map(item => ({
      ...item,
      text: item.qualitativeField && groupSubmission?.answers.find(answer => answer.property === item.qualitativeField)?.value,
      help: item.HelpContent && <InfoDialog title={item.title} color={colors.socialCompetencies[item.quantitativeField.replace(/-./g, match => match[1].toUpperCase())]}><item.HelpContent/></InfoDialog>,
      comparison: item.ComparisonSlider && <item.ComparisonSlider values={quantitativeValues[item.quantitativeField]}/>
    }))
    .filter(item => item.text ||
        quantitativeValues[item.quantitativeField]?.self !== undefined ||
        quantitativeValues[item.quantitativeField]?.external.length
    )
    .map(item => <Grid item key={item.title}>
      <Typography variant='h3' className={classes.title}>{item.title} {item.help}</Typography>
      {item.comparison}
      {item.text && <Typography paragraph>{item.text}</Typography>}
    </Grid>)

  return <Grid container direction='column' spacing={1} className={classes.content}>
    { items }
    <LearningGoal learnableMoment={lm} editable={!!own}/>
    <Grid item className={classes.contentFooter}>
      <small>
        <i>
          Erstellt: {moment(lm.createdAt).format('HH:mm')} Uhr,
          zuletzt bearbeitet: {moment(lm.updatedAt).format('HH:mm')} Uhr{ moment(lm.updatedAt) - moment(lm.createdAt) > 24 * 60 * 60 * 1000 ? ` (${moment(lm.updatedAt).format('DD.MM.YYYY')})` : null}
        </i>
      </small>
    </Grid>
  </Grid>

}

const Comparison  = ({values, title, Chip}) => {
  const self = values.self ?? '-';
  const external = values.external.length ?
    Math.round(values.external.reduce((sum, val) => sum + +val, 0) / values.external.length) :
    '-';

  return <>
    <Tooltip title={`${title} (Selbst)`}><Chip size='small' label={self}/></Tooltip>
    <Tooltip title={`${title} (Fremd)`}><Chip size='small' label={external}/></Tooltip>
  </>
}

const valuesForComparison = (submissions, fields) => {
  return fields
    .map(socialCompetency => ({
      competency: socialCompetency,
      self: submissions[observationRole.SELF][0]?.answers.find(answer => answer.property === socialCompetency)?.value,
      external: [
        ...submissions[observationRole.PEER],
        ...submissions[observationRole.TUTOR],
        ]
        .map(peerSubmission => peerSubmission.answers.find(answer => answer.property === socialCompetency)?.value)
        .filter(value => value !== undefined)
    }))
    .reduce((obj, {competency, self, external}) => ({...obj, [competency]: {self, external}}), {})
}

const AgencyChip = withStyles({ root: { background: colors.socialCompetencies.agency }, sizeSmall: { width: '24px' } })(Chip)
const CommunionChip = withStyles({ root: { background: colors.socialCompetencies.communion }, sizeSmall: { width: '24px' } })(Chip)
const InterpersonalResilienceChip = withStyles({ root: { background: colors.socialCompetencies.interpersonalResilience }, sizeSmall: { width: '24px' } })(Chip)
const EpaChip = withStyles({ root: { background: purple[200] }, sizeSmall: { width: '24px' } })(Chip)

export default function LearnableMomentEntry ({lm, expanded, own}) {
  const { context, createdAt, observations } = lm
  const submissions = ['SELF', 'GROUP', 'PEER', 'TUTOR']
    .map(role => ({
      role: observationRole[role],
      submissions: observations
        .find(observation => observation.role === observationRole[role])?.submissions || []
    }))
    .reduce((obj, item) => ({...obj, [item.role]: item.submissions}), {})

  const hasDetails = !!Object.values(submissions).find(submissions => submissions.length);
  return <Accordion disabled={!hasDetails} defaultExpanded={!!expanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Grid container justifyContent='space-between' spacing={1}>
        <Grid item>
          {moment(createdAt).format('DD.MM.YYYY')}:&nbsp;
          <b>{context.title}</b>
        </Grid>
        <Grid item>
          <ObservationHeader submissions={hasDetails && submissions}/>
        </Grid>
      </Grid>
    </AccordionSummary>
    { hasDetails ?
      <AccordionDetails>
        <ObservationContent submissions={submissions} lm={lm} own={own}/>
      </AccordionDetails> : null}
  </Accordion>
}