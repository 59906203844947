import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';

const NotFound = () => {
  const dispatch = useDispatch()

  return <div style={{ padding: 24 }}>
    <Typography variant="body1">Diese Seite wurde nicht gefunden.</Typography>
    <Button variant="contained" color="primary" onClick={() => dispatch(push('/'))}>Zurück</Button>
  </div>
}

export default NotFound
