import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { observationState as observationStateEnum } from '../ducks/learnableMoments';
import { list as listLearnableMoments } from '../ducks/learnableMoments/api';
import Person from './Person';

const currentSemester = {
  title: 'WS 24/25',
  timespan: [
    1727733600000, // 1.10.2024, 0:00
    1743458399000, // 31.3.2025, 23:59
  ],
}
// Other semesters
// title: 'SoSe 24',
// timespan: [
//   1711922400000, // 1.4.2024, 0:00
//   1727733599000, // 30.9.2024, 23:59
// ],
// title: 'WS 23/24',
// timespan: [
//   1696118400000, // 1.10.2023, 0:00
//   1711929540000, // 31.3.2024, 23:59
// ],
// title: 'SoSe 23',
// timespan: [
//   1680307200000, // 1.4.2023, 0:00
//   1696118399000, // 30.9.2023, 23:59
// ],
// title: 'WS 22/23',
// timespan: [
//   1664582400000, // 1.10.2022, 0:00
//   1680307199000, // 31.3.2023, 23:59
// ],
// title: 'SoSe 22',
// timespan: [
//   1648764000000, // 1.4.2022, 0:00
//   1664575140000, // 30.9.2022, 23:59
// ],

function LearnableMomentList() {
  const user = useSelector(state => state.user.user)
  const [learnableMoments, setLearnableMoments] = useState(null)
  const [hideFinishedMoments, setHideFinishedMoments] = useState(false)

  const handleChange = event => setHideFinishedMoments(event.target.checked)

  useEffect(() => listLearnableMoments(user, {timeFrom: currentSemester.timespan[0], timeTo: currentSemester.timespan[1]})
      .then(lms => lms.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)))
      .then(lms => setLearnableMoments(lms))
    , [user]
  )

  return (
    <>
      <strong>Liste aller Lernmomente</strong><br/>
      <i>Hinweis: Es werden nur die Lernmomente des aktuellen Semesters angezeigt ({currentSemester.title}), deren Autor:in Sie sind</i>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={hideFinishedMoments} onChange={handleChange}/>}
          label="Abgeschlossene Lernmomente verstecken"
        />
      </FormGroup>
      <ul>
        { !learnableMoments ?
          'Wird geladen...' :
          !learnableMoments.length ?
            'Keine Lernmomente vorhanden' :
            learnableMoments
            .filter(({observationState}) => !hideFinishedMoments || observationState !== observationStateEnum.FINISHED)
            .map(({id, student, room, author, observationState, createdAt}) =>
              <li key={id}>
                <Link to={`/learnable-moments/${id}`}>Lernmoment für <Person person={student}/></Link> vom { moment(createdAt).format('DD.MM.YYYY, HH:mm') } Uhr, Raum: { room?.title || 'Nicht gesetzt' }, Status: { observationState !== observationStateEnum.FINISHED ? <b>{observationState}</b> : observationState }, Autor: <Person person={author}/>
              </li>
            )}
      </ul>
    </>
  )

}

export default LearnableMomentList
