import fetch from '../fetch';

const apiUrl = process.env.REACT_APP_BACKEND_URL
const medforgeUrl = process.env.REACT_APP_MEDFORGE_URL

export const createLoginToken = () => {
  const url = `${apiUrl}/login-tokens/new`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders
  })
    .then(handleResponse)
}

export const getLoginToken = loginToken => {
  const url = `${apiUrl}/login-tokens/${loginToken}`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders
  })
    .then(handleResponse)
}

export const authenticateLoginToken = (user, loginToken) => {
  const url = `${apiUrl}/login-tokens/${loginToken}/authenticate`
  return  fetch(url, {
    method: 'POST',
    headers: defaultAuthHeaders(user)
  })
    .then(handleResponse)
}

export const addMinimalPermission = (user) => {
  // We use myepas-permission here, because in the long run, we want to use one dashboard for myepas and 360grad
  const url = `${medforgeUrl}/api/user/add-minimal-mypeas-permission`
  return fetch(url, {
    method: 'POST',
    headers: defaultAuthHeaders(user)
  })
    .then(handleResponse)
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const defaultAuthHeaders = user => ({
  ...defaultHeaders,
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => response.json()