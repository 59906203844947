import { Grid, Slider } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { colors } from '../config';
import InfoDialog, { EpaContent } from './InfoDialog';

export const AgencyComparisonSlider = withStyles({
  root: {'&.Mui-disabled': {color: colors.socialCompetencies.agency}},
  valueLabel: {'& *': {color: colors.socialCompetencies.agency}},
  mark: {backgroundColor: colors.socialCompetencies.agency},
})(props => <ComparisonSlider min={1} max={6} {...props}/>)

export const CommunionComparisonSlider = withStyles({
  root: {'&.Mui-disabled': {color: colors.socialCompetencies.communion}},
  valueLabel: {'& *': {color: colors.socialCompetencies.communion}},
  mark: {backgroundColor: colors.socialCompetencies.communion},
})(props => <ComparisonSlider min={1} max={6} {...props}/>)

export const InterpersonalResilienceComparisonSlider = withStyles({
  root: {'&.Mui-disabled': {color: colors.socialCompetencies.interpersonalResilience}},
  valueLabel: {'& *': {color: colors.socialCompetencies.interpersonalResilience}},
  mark: {backgroundColor: colors.socialCompetencies.interpersonalResilience},
})(props => <ComparisonSlider min={1} max={6} {...props}/>)

export const EpaComparisonSlider = withStyles({
  root: {'&.Mui-disabled': {color: purple[200]}},
  valueLabel: {'& *': {color: purple[200]}},
  mark: {backgroundColor: purple[200]},
})(props => <Grid container direction='row' alignItems='baseline'>
  <ComparisonSlider min={0} max={5} {...props}/>
  <InfoDialog title="Supervisionslevel"><EpaContent/></InfoDialog>
</Grid>)

const ComparisonSlider = withStyles({
  root: {
    width: 300,
    padding: '15px 0',
    marginLeft: '10px',
    filter: 'brightness(0.9)'
  },
  valueLabel: {
    top: -15,
    '& *': {
      background: 'transparent',
    },
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
})(({values, min, max, ...rest}) => {
  const preparedValues = {}
  if (values.self !== undefined) {
    preparedValues.self = +values.self
  }
  if (values.external !== undefined && values.external.length) {
    preparedValues.external = Math.round(values.external.reduce((sum, val) => sum + +val, 0) / values.external.length)
  }
  const defaultValues = Object.values(preparedValues)
  if (!defaultValues.length) {
    return null
  }
  const valueFn = val => {
    if (preparedValues.self === val || preparedValues.external === val) {
      if (preparedValues.external !== val) {
        return 'Selbst'
      }
      if (preparedValues.self !== val) {
        return 'Fremd'
      }
      return 'Selbst/Fremd'
    }
    return val
  }

  const marks = Array.from(Array(max -  min + 1))
    .map((_, i) => ({
      value: i + min,
      label: (i + min).toString()
    }))

  return <Slider
    min={min}
    max={max}
    marks={marks}
    track={false}
    value={defaultValues}
    valueLabelFormat={valueFn}
    valueLabelDisplay="on"
    disabled={true}
    {...rest}
  />
})
