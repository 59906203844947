import moment from 'moment';

import fetch from '../fetch';

const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const read = (user, externalCourseIds) => {
  const promises = externalCourseIds.map(
    async externalCourseId => {
      const url = `${mongoUrl}/courses/${externalCourseId}`
      return fetch(url, {
          method: 'GET',
          headers: defaultHeaders(user)
        })
        .then(response => handleResponse(response))
        .catch(error => {
          return;
        });
    }
  );

  return Promise.all(promises)
    .then(results => results
      .filter(course => !!course)
      .reduce((prev, curr) => [...prev, curr], [])
    )
}

export const eventsForCourseIds = (user, externalCourseIds) => {
  return read(user, externalCourseIds)
    .then(courses => courses
      .map(course => course.events
        .map((event, i) => ({
          ...event,
          shortName: course.shortName,
          meta: event.meta || '#' + event.id,
        }))
      )
      .reduce((prev, curr) => [...prev, ...curr], [])
    )
}

export const participants = (user, courseEventId) => {
  if (!courseEventId) {
    return
  }
  const url = `${mongoUrl}/course-events/${courseEventId}/participants`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  return response.json()
}

export const titleForEvent = event => {
  if (!event || !event.shortName) {
    return 'Unbekannt'
  }
  if (!event.startsAt || !event.endsAt) {
    return event.shortName
  }
  const titleString = `${event.shortName}&nbsp;<b>${event.meta}</b>&nbsp;(${moment(event.startsAt).format('DD.MM.YYYY')},&nbsp;<b>${moment(event.startsAt).format('HH:mm')} – ${moment(event.endsAt).format('HH:mm')} Uhr</b>)`
  const html = <div dangerouslySetInnerHTML={{__html: titleString}}></div>
  return html;
}