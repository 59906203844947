import { colors, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto'
  }
}));

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
})(Tooltip);

const FreetextItem = ({ value }) => {
  const classes = useStyles()
  if (value === null) return <span style={{color: colors.grey[400]}}>✕</span>

  return (
    <StyledTooltip title={value} arrow placement="bottom">
      <div className={classes.root}>
        {value}
      </div>
    </StyledTooltip>
  )
}

export default FreetextItem