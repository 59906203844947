import { observationRole } from '../learnableMoments';
import { courses } from './constants';
import types from './types';

const INITIAL_STATE = {
  course: courses[0],
  role: observationRole.TUTOR,
  room: null,
  spRoom: null,
  group: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_COURSE:
      return { ...state, course: action.payload, error: '' }
    case types.SET_ROLE:
      return { ...state, role: action.payload, error: '' }
    case types.SET_ROOM:
      return { ...state, room: action.payload, error: '' }
    case types.SET_SP_ROOM:
      return { ...state, spRoom: action.payload, error: '' }
    case types.SET_GROUP:
      return { ...state, group: action.payload, error: '' }
    case types.ADD_STUDENT:
      return { ...state, group: {...state.group, children: [...state.group.children.filter(i => i.id !== action.payload.id), action.payload]}, error: '' }
    case types.REMOVE_STUDENT:
      return { ...state, group: {...state.group, children: state.group.children.filter(i => i.id !== action.payload)}, error: '' }
    default:
      return state
  }
}

export default reducer
