import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import LearnableMomentListForStudent from './LearnableMomentListForStudent';
import Person from './Person';

const useStyles = makeStyles({
  dialogPaper: {
    width: '100%',
    margin: '10px'
  },
})

export default function StudentOverview({student}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Vergangene Lernmomente zeigen">
        <IconButton size="small" color="primary" onClick={handleClickOpen}><FolderOpenIcon/></IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby="events-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        classes={{paper: classes.dialogPaper}}
      >
        <DialogTitle id="events-dialog-title">Vergangene Lernmomente für <Person person={student}/></DialogTitle>
        <DialogContent>
          <LearnableMomentListForStudent student={student}/>
        </DialogContent>
      </Dialog>
    </>
  )
}