import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { eventsForCourseIds, participants as getParticipantsByEventId, titleForEvent } from '../ducks/courses/api';


const ConnectedDialog = ({  onClose, selectedValue, open }) => {

  const user = useSelector(state => state.user.user)
  const course = useSelector(state => state.context.course)

  const [events, setEvents] = useState([])
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (open) {
      if (selectedValue) {
        setSelected(selectedValue.id)
      } else {
        setSelected(null)
      }
    }
  }, [selectedValue, open])

  useEffect(() => {
    if (open) {
      if (course.externalIds) {
        eventsForCourseIds(user, course.externalIds)
          .then(events => setEvents(events
            .filter(event => moment(event.startsAt).isSame(moment(),'day'))
          ))
      } else {
        setEvents([])
      }
    }
  }, [user, course, open])

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleEmptyGroupClick = () => {
    onClose({
      id: 'null',
      shortName: 'Leere Gruppe',
      children: [],
    })
  }

  const handleListItemClick = (value) => {
    if (selected === value.id) {
      return
    }
    setSelected(value.id)
    getParticipantsByEventId(user, value.id)
      .then(participants => onClose({
        ...value,
        children: participants
      }))
  };

  const renderChildren = children => {
    if (!children.length) {
      return <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Achtung: Keine Veranstaltung am heutigen Tag gefunden.
        </DialogContentText>
        <Button variant="outlined" color="primary" onClick={handleEmptyGroupClick}>
          Leere Gruppe setzen (muss manuell gefüllt werden)
        </Button>

      </DialogContent>
    }
    return <List>
        <ListItem button onClick={handleEmptyGroupClick} selected={selected === 'null'}>
          <ListItemText primary="Leere Gruppe (muss manuell gefüllt werden)"/>
        </ListItem>
        {children
          .map(i =>
            <ListItem button key={i.id} onClick={() => handleListItemClick(i)} selected={selected === i.id}>
              <ListItemText primary={titleForEvent(i)}/>
            </ListItem>
          )}
      </List>
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="events-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="events-dialog-title">Verfügbare Termine</DialogTitle>
      { renderChildren(events) }
    </Dialog>
  );
}

export default function GroupSelection({selectedGroup, onChangeSelection, customText}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    onChangeSelection(value);
  };

  return (
    <>{
        selectedGroup ?
        <Tooltip title="Gruppe ändern">
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            {customText || titleForEvent(selectedGroup)}
          </Button>
        </Tooltip> :
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Gruppe auswählen
        </Button>
      }
      <ConnectedDialog selectedValue={selectedGroup} open={open} onClose={handleClose}/>
    </>
  )
}