export { default as learnableMomentsApi } from './api'
export { default as learnableMomentsOperations } from './operations'

export const observationState = {
  NOT_YET_STARTED: 'not-yet-started',
  STARTED: 'started',
  FINISHED: 'finished',
}

export const observationRole = {
  SELF: 'self',
  PEER: 'peer',
  TUTOR: 'tutor',
  SP: 'simulation-patient',
  GROUP: 'group',
}

export const contextType = {
  COURSE: 'course',
}

export const assessmentItemType = {
  EPA: 'epa',
  NEPA: 'n-epa',
}

export const actionItemType = {
  LEARNING_GOAL: 'learning-goal',
}