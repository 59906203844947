import fetch from '../fetch';
import { observationRole } from '../learnableMoments';

const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const getForSpInRoom = (user, roomId) => {
  const url = `${mongoUrl}/observations?room=${roomId}&role=${observationRole.SP}`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  if (!response.ok) throw new Error(response.statusText)
  return response.json()
}