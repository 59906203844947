import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import React, { useState } from 'react';

const ConnectedDialog = ({title, color, onClose, open, children}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="info-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="info-dialog-title" style={{background: color}}>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default function InfoDialog({title, ...rest}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={`Weitere Informationen zu ${title}`}>
        <IconButton onClick={handleClickOpen}><InfoRounded/></IconButton>
      </Tooltip>
      <ConnectedDialog title={title} open={open} onClose={handleClose} {...rest}>
      </ConnectedDialog>
    </>
  )
}

export const AgencyContent = () => <Grid container>
  <Grid item>
    <Typography paragraph>Der/die Studierende erkennt, in welchen Situationen durchsetzungsstarkes, selbstsicheres, entschlossenes und energisches Verhalten angebracht ist und kann dies entsprechend umsetzen.<br/><i>(z.B. kann Patient:innen überzeugen, mitreißen, Konflikte austragen und Verantwortung übernehmen)</i></Typography>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>1 bis 3</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>In sich zusammengesunkene, zurückhaltende, nicht zugewandte Körperhaltung</li>
          <li>Keine oder zurückhaltende Mimik und Gestik</li>
          <li>Hält keinen Blickkontakt / weicht Blickkontakt aus</li>
          <li>Instabiler, stockender Redefluss</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Zeigt sich im Gespräch passiv / führt nicht die Interaktion</li>
          <li>Ergreift keine Initiative, um die Richtung des Gesprächs zu steuern</li>
          <li>Keine oder unspezifische Aussagen</li>
        </ul>
      </li>
    </ul>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>4 bis 6</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>Angemessen selbstsichere, aufrechte und zugewandte Körperhaltung</li>
          <li>Durchsetzungsstarke und selbstsichere Mimik und Gestik</li>
          <li>Hält Blickkontakt</li>
          <li>Durchgehend stabiler, sicherer Redefluss</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Übernimmt aktiv die Gesprächsführung / führt die Interaktion</li>
          <li>Ergreift (in angemessenem Rahmen) die Initiative (z.B. durch Unterbrechungen), um gezielt die Richtung des Gesprächs zu steuern</li>
          <li>Klare, in Richtung weisende Aussagen</li>
        </ul>
      </li>
    </ul>
  </Grid>
</Grid>

export const CommunionContent = () => <Grid container>
  <Grid item>
    <Typography paragraph>Die/der Studierende erkennt, in welchen Situationen warmherziges, freundliches und mitfühlendes Verhalten angebracht ist und kann dies entsprechend umsetzen.<br/><i>(z.B. kann mit hilfsbedürftigen, traurigen und verzweifelten Patient:innen umgehen)</i></Typography>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>1 bis 3</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>Keine oder unpassende Zuwendung</li>
          <li>Unfreundliche oder abgewandte Mimik und Gestik</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Kein aktives Zuhören</li>
          <li>Unfreundliche oder unempathische Rückfragen</li>
          <li>Keine oder unpassende Reaktion auf Hinweise und Bedürfnisse</li>
        </ul>
      </li>
    </ul>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>4 bis 6</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>Angemessene, aufmerksame, positiv-vertrauensvolle Zuwendung</li>
          <li>Freundliche und zugewandte Gestik und Mimik</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Aktives Zuhören (positives Feedback, zustimmende und beruhigende Aussagen, Paraphrasieren, Pausen aushalten)</li>
          <li>Freundliche und empathische Rückfragen</li>
          <li>Angemessenes Eingehen auf Hinweise und Bedürfnisse</li>
        </ul>
      </li>
    </ul>
  </Grid>
</Grid>

export const InterpersonalResilienceContent = () => <Grid container>
  <Grid item>
    <Typography paragraph>Der/die Studierende erkennt, wann es im Spannungsfeld interpersoneller Interaktionen wichtig ist, gelassen, entspannt und emotional ausgeglichen zu bleiben und kann dies auch in belastenden Situationen umsetzen.<br/><i>(z.B. kann Stress, negatives Feedback, Kritik und Unsicherheit/Mehrdeutigkeit ertragen)</i></Typography>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>1 bis 3</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>Nervöser, nicht-zielgerichteter Wechsel der Sitz-/Steh-Position</li>
          <li>Nervöse (häufig wechselnde) und/oder starre (erstarrte) Mimik und Gestik</li>
          <li>Nervöses Anfassen (z.B. Gesicht, Haare)</li>
          <li>Auftreten von Unsicherheiten in der Sprache (Füllworte, Abbrechen von Sätzen, Verhaspeln, Stottern, Wiederholen von Satzteilen)</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Verunsicherte Rückfragen (Paraphrasen und Fragen, die Unsicherheit signalisieren oder zeigen, dass Person nicht mehr weiterweiß)</li>
          <li>Wiederholende Aussagen zur Rechtfertigung</li>
          <li>Negative, pessimistische und / oder resignative Aussagen</li>
          <li>Gereizte / überempfindliche Reaktionen</li>
        </ul>
      </li>
    </ul>
  </Grid>
  <Grid item>
    Kompetenz-Ausprägungsgrad <b>4 bis 6</b>
  </Grid>
  <Grid item>
    <ul>
      <li>Non-Verbal
        <ul>
          <li>Entspannte Sitz-/Steh-Position</li>
          <li>Gelassene Mimik und Gestik</li>
          <li>Kein nervöses Anfassen</li>
          <li>Kein Auftreten von Unsicherheiten in der Sprache</li>
        </ul>
      </li>
      <li>Verbal
        <ul>
          <li>Keine verunsicherten Rückfragen</li>
          <li>Keine Aussagen zur Rechtfertigung</li>
          <li>Keine negativen, pessimistischen oder resignativen Aussagen</li>
          <li>Keine gereizten / überempfindlichen Reaktionen</li>
        </ul>
      </li>
    </ul>
  </Grid>
</Grid>

export const EpaContent = () => <Grid container>
  <Typography paragraph><b>0:</b> Ich würde die/den Student:in bei dieser Tätigkeit <u>nicht zuschauen</u> lassen</Typography>
  <Typography paragraph><b>1:</b> Ich würde die/den Student:in diese Tätigkeit <u>nur beobachten</u> lassen</Typography>
  <Typography paragraph><b>2:</b> Ich würde die/den Student:in diese Tätigkeit mit <u>vollständiger direkter Supervision</u> bzw. der Möglichkeit zum <u>direkten Eingreifen</u> übernehmen lassen</Typography>
  <Typography paragraph><b>3:</b> Ich würde die/den Student:in diese Tätigkeit unter <u>räumlich entfernter Supervision mit direkter Erreichbarkeit und Kontrolle des Ergebnisses</u> ausführen lassen</Typography>
  <Typography paragraph><b>4:</b> Ich würde die/den Student:in diese Tätigkeit unter <u>räumlich entfernter Supervision ohne direkte Erreichbarkeit</u> ausführen lassen</Typography>
  <Typography paragraph><b>5:</b> Ich würde der/dem Student:in zutrauen, diese Tätigkeit unter <u>voller Verantwortung durchzuführen und andere in dieser Tätigkeit anzuführen und zu supervidieren</u></Typography>
</Grid>
