import { colors, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { cloneElement, useState } from 'react';

import { colors as customColors } from '../../config';
import { conceptualFrameworkItems } from '../../ducks/context';
import { observationRole } from '../../ducks/learnableMoments';
import CircleGraphItem from './CircleGraphItem';
import FreetextItem from './FreetextItem';
import IconGraphItem from './IconGraphItem';
import SquareGraphItem from './SquareGraphItem';

const gridItemBasis = 60

const roles = [
  { name: observationRole.SELF, title: 'Handelnde:r', color: colors.blue[100], borderColor: colors.grey[600]},
  { name: observationRole.PEER, title: 'Peers', color: colors.blue[300], borderColor: colors.grey[600]},
  { name: observationRole.TUTOR, title: 'Dozent:in/​Tutor:in', color: colors.blue[800], borderColor: colors.grey[900]},
  { name: observationRole.SP, title: 'SP', color: colors.blue[200], borderColor: colors.grey[600], notForContext: ['emergency-medicine-2']},
  { name: observationRole.GROUP, title: 'Gruppe', color: colors.blue[300], borderColor: colors.grey[600]},
]
const properties = [
  {
    name: 'complexity',
    title: 'Komplexität',
    itemComponent: <IconGraphItem
      icon0={<Tooltip title="Einfach"><ExtensionOutlinedIcon/></Tooltip>}
      icon1={<Tooltip title="Komplex"><ExtensionIcon/></Tooltip>}
    />
  },
  {
    name: 'hygiene',
    title: 'Grundprinzipien hygienischen Arbeitens',
    itemComponent: <CircleGraphItem min={1} max={5} basis={gridItemBasis}/>,
  },
  {
    name: 'agency',
    title: 'Agency',
    subtitle: 'durchsetzungsstarkes, selbstsicheres, entschlossenes und energisches Verhalten',
    itemComponent: <CircleGraphItem min={1} max={6} basis={gridItemBasis}/>,
  },
  {
    name:'communion',
    title: 'Communion',
    subtitle: 'warmherziges, freundliches und mitfühlendes Verhalten',
    itemComponent: <CircleGraphItem min={1} max={6} basis={gridItemBasis}/>,
  },
  {
    name: 'interpersonal-resilience',
    title: 'Interpersonelle Resilienz',
    subtitle: 'gelassen, entspannt und emotional ausgeglichen',
    itemComponent: <CircleGraphItem min={1} max={6} basis={gridItemBasis}/>,
  },
  { name: 'n-epa', title: 'nEPA', itemComponent: <SquareGraphItem min={0} max={5} basis={gridItemBasis}/>},
  { name: 'agency-text', title: 'Agency', subtitle: 'durchsetzungsstarkes, selbstsicheres, entschlossenes und energisches Verhalten', itemComponent: <FreetextItem/>},
  { name: 'communion-text', title: 'Communion', subtitle: 'warmherziges, freundliches und mitfühlendes Verhalten', itemComponent: <FreetextItem/>},
  { name: 'interpersonal-resilience-text', title: 'Interpersonelle Resilienz', subtitle: 'gelassen, entspannt und emotional ausgeglichen', itemComponent: <FreetextItem/>},
  { name: 'learning-goal-a', title: 'Vorsatz A', subtitle: 'Was genauso machen?', itemComponent: <FreetextItem/>},
  { name: 'learning-goal-b', title: 'Vorsatz B', subtitle: 'Was nie wieder?', itemComponent: <FreetextItem/>},
  { name: 'learning-goal-c', title: 'Vorsatz C', subtitle: 'Was anders machen?', itemComponent: <FreetextItem/>},
]

const backgroundColors = {
  'agency': customColors.socialCompetencies.agency,
  'communion': customColors.socialCompetencies.communion,
  'interpersonal-resilience': customColors.socialCompetencies.interpersonalResilience,
  'agency-text': customColors.socialCompetencies.agency,
  'communion-text': customColors.socialCompetencies.communion,
  'interpersonal-resilience-text': customColors.socialCompetencies.interpersonalResilience,
  'learning-goal-b': 'rgb(245 245 245)',
}

const roleOrder = items => filterRoles(items).map(g => g.name)
const propertyOrder = items => filterProperties(items).map(g => g.name)

const buildGrid = (cols, rows) => {
  const lines = [
    ...rows.map(p => [`${p}-toggle`, `${p}-title`].concat(cols.map(r => `${p}-${r}`)).join(' ')),
    ['empty', 'empty'].concat(cols.map(r => `${r}-title`)).join(' '),
  ]
  .map(l => `"${l}"`)
  .join("\n")
  return lines
}

const useStyles = makeStyles((theme) => ({
  grid: ({items}) => ({
    display: 'grid',
    gridTemplateAreas: buildGrid(roleOrder(items), propertyOrder(items)),
    gridAutoRows: `${gridItemBasis + 20}px`,
  }),
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  },
  gridColTitle: {
    textAlign: 'center',
    paddingRight: '10px',
    fontStyle: 'italic'
  },
  gridRowTitle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  gridRowSubtitle: {
    color: colors.grey[600],
    fontSize: '0.9em',
    maxWidth: 200,
    lineHeight: 1.2,
  }
}));

const filterRoles = items => roles.filter(role => items.find(item => item.role === role.name))
const filterProperties = items => properties.filter(property => items.find(item => item.name === property.name))

export default function Graph({ items, context }) {
  const classes = useStyles({items});

  const [visibleProperties, setVisibleProperties] = useState(propertyOrder(items))

  const graphItems = items
    .map(item => {
      const { itemComponent } = properties.find(p => p.name === item.name)
      const { color, borderColor } = roles.find(r => r.name === item.role)
      const component = cloneElement(itemComponent, {color, borderColor})
      return {...item, component}
    })
  const filteredItems = graphItems.filter(i => visibleProperties.includes(i.name))
  const filteredRoles = filterRoles(items)
  const filteredProperties = filterProperties(items)
    .map(property => property.name === 'n-epa' && context.assessmentItems.length ? {
      ...property,
      subtitle: conceptualFrameworkItems.find(item => item.id === context.assessmentItems[0].id)?.title
    } : property)

  const gridTemplateColumnSizes = [50, 200]
  if (filteredRoles.length > 1) {
    gridTemplateColumnSizes.push(
      ...roleOrder(items).map(r =>
        Math.max(
          ...items
            .filter(i => i.role === r)
            .map(i => i.value.length),
          1
        ) * gridItemBasis + 20
      )
    )
  }

  const gridTemplateColumns = gridTemplateColumnSizes
    .map(v => v + 'px')
    .join(' ')

  const toggleButton = (property) => {
    const visible = visibleProperties.includes(property)
    const toggledProperties = visibleProperties.filter(p => p !== property)
    if (!visible) {
      toggledProperties.push(property)
    }
    return (
      <Tooltip title={visible ? 'Verbergen' : 'Einblenden'}>
        <IconButton onClick={() => setVisibleProperties(toggledProperties)}>
          { visible ? <VisibilityIcon/> : <VisibilityOffIcon/> }
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div className={classes.grid} style={{gridTemplateColumns}}>
      {filteredItems.map(i =>
        <div key={i.id} className={classes.gridItem} style={{gridArea: `${i.name}-${i.role}`, backgroundColor: backgroundColors[i.name]}}>
          {i.value.map((v, j, a) => <span style={{ display: 'flex', justifyContent: 'center', flexBasis: `${100/a.length}%`, maxHeight: '100%' }} key={j}>{ cloneElement(i.component, {value: v})}</span>)}
        </div>
      )}
      {filteredProperties.map(i =>
        <div key={i.name} className={classes.gridRowTitle} style={{gridArea: `${i.name}-title`, backgroundColor: backgroundColors[i.name]}}>
          <div>{ i.title }</div>
          <div className={classes.gridRowSubtitle}>{ i.subtitle }</div>
        </div>
      )}
      {filteredProperties.map(i =>
        <div key={`${i.name}-toggle`} className={classes.gridRowTitle} style={{gridArea: `${i.name}-toggle`, backgroundColor: backgroundColors[i.name]}}>{ toggleButton(i.name) }</div>
      )}
      {filteredRoles.map(i =>
        <div key={i.name} className={classes.gridColTitle} style={{gridArea: `${i.name}-title`}}>{ i.title }</div>
      )}
    </div>
  )

}