import Typography from '@material-ui/core/Typography/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { authenticateLoginToken } from '../../ducks/auth/api';

const AuthenticateLoginToken = () => {
  const { token: loginToken } = useParams()
  const user = useSelector(state => state.user.user)

  const [error, setError] = useState(null)

  useEffect(() => {
    if (error === null) {
      authenticateLoginToken(user, loginToken)
        .then(_ => setError(''))
        .catch(error => setError(error))
    }
  }, [user, error, loginToken])

  let result;

  if (error === null) {
    result = 'Token wird authentifiziert...'
  } else {
    if (error === '') {
      result = 'Sie wurden auf dem externen Gerät angemeldet.'
    } else {
      result = `Es ist ein Fehler aufgetreten: ${error}`
    }
  }

  return <div style={{ padding: 24 }}>
    <Typography variant="h4">Externes Gerät anmelden</Typography>
    <Typography variant="body1">{ result }</Typography>
  </div>
}

export default AuthenticateLoginToken
