import { userOperations } from './users'
import store from '../index'
import config from '../config'

const controller = new AbortController()
const { signal } = controller

const extFetch = (url, args) => {
  return fetch(url, { ...args, signal })
    .then(handleErrors)
}

const handleErrors = async response => {
  if (!response.ok) {
    let body;
    try {
      body = await response.json()
    } catch {}
    const { status } = response
    const statusText = body.statusText || response.statusText
    switch (status) {
      case 401:
        let currentLocation = store.getState().router.location.pathname
        localStorage.setItem(`${config.localStoragePrefix}location`, currentLocation)
        controller.abort()
        store.dispatch(userOperations.signOut())
        store.dispatch(userOperations.authenticateWithMedforge())
        throw new Error(statusText)

      default:
        throw new Error(statusText)
    }
  }
  return response
}

export default extFetch