import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { observationState } from '../ducks/learnableMoments';
import { getForSpInRoom as getObservationsForSpInRoom } from '../ducks/observations/api';

const apiUrl = process.env.REACT_APP_BACKEND_URL


function SpSession() {
  const user = useSelector(state => state.user.user)
  const room = useSelector(state => state.context.room)
  const role = useSelector(state => state.context.role)

  const [observations, setObservations] = useState(null)
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (waiting) {
      return
    }
    if (room) {
      setWaiting(true)
      getObservationsForSpInRoom(user, room.id)
        .then(observations => setObservations(observations))
        .finally(_ => setTimeout(() => {
          setWaiting(false)
        }, 10000))
    }
  }, [room, user, waiting]);

  if (!room || !role) {
    return <Redirect to={'/context-selection'}/>
  }

  return (
    <div>
      Sie befinden sich im Raum <strong>{ room.title }</strong>
      <hr/>
      { buildObservationText(observations) }
    </div>
  )
}

function buildObservationText(observations) {
  if (!observations) {
    return (<div>Raum wird geladen...</div>)
  }

  if (!observations.length) {
    return (
      <div>
        <strong>Bitte warten</strong><br/>
        Noch ist kein:e Student:in für Ihren Raum registriert. Warten Sie bitte einen Moment.
      </div>
    )
  }

  let warning;
  if (observations.length > 1) {
    const names = observations.map(observation => `${observation.student?.firstName} ${observation.student?.lastName}`).join(', ')
    warning = <i>Achtung: mehr als ein:e Studentin für diesen Raum registriert ({names}).</i>
  }
  const observation = observations[0]
  const studentName = observation && `${observation.student?.firstName} ${observation.student?.lastName}`;

  let infoText;
  if (observation.lm_state === observationState.NOT_YET_STARTED) {
    infoText = <div>
      <strong>Student:in registriert</strong><br/>
      Für Ihren Raum ist <strong>{ studentName }</strong> registriert. Im Anschluss an die Szene können Sie hier Feedback dazu abgeben.
    </div>
  } else {
    if (observation.state !== observationState.FINISHED) {
      const url = `${apiUrl}/observations/${observation.token}`;
      infoText = <div>
        <p><strong>Feedbackphase gestartet</strong></p>
        <p>Klicken Sie auf folgende Schaltfläche, um ein Feeback für <strong>{studentName}</strong> abzugeben:{' '}<Button variant="contained" color="primary" href={url}>Feedback abgeben</Button></p>
      </div>
    } else {
      infoText = <div>
        <p><strong>Feedback abgegeben</strong></p>
        <p>Ihr Feedback für <strong>{studentName}</strong> wurde erfasst. Vielen Dank!</p>
      </div>
    }
  }
  return (
    <div>
      <div>{warning}</div>
      {infoText}
    </div>
  )
}

export default SpSession
