import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow/Grow';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import IfasLogo from '../../assets/images/ifas_logo.png';
import ShmLogo from '../../assets/images/shm_logo.png';
import config from '../../config';
import { userOperations } from '../../ducks/users';
import QrCodeLogin from './QrCodeLogin';

const useStyles = makeStyles(theme => ({
  isStaging: {
    '&::before': {
      content: '"Achtung: Testsystem"',
      color: '#fff',
      background: 'red',
      display: 'block',
      textAlign: 'center',
    },
    border: '4px solid red',
  },
  background: {
    height: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`,
    position: 'absolute',
    left: '0px',
    width: '100%',
    overflow: 'hidden'
  },
  progress: {
    margin: theme.spacing(2)
  },
  cardContent: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: 250,
    height: 55,
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  logo: {
    width: '60px',
  }
}))

const LoginForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)
  const intendedUrl = useSelector(state => state.user.intendedUrl)
  const loading = useSelector(state => state.loading)

  const getStartPathForUser = user => {
    if (user.permissions.includes('shm-sessions')) {
      return '/session';
    }
    if (user.permissions.includes('myepas-read-own-records')) {
      return '/own-learnable-moments';
    }
    return '/403';
  }

  if (loading) return <LinearProgress color={'secondary'}/>
  if (user) return <Redirect to={intendedUrl || getStartPathForUser(user)}/>

  return (
    <Grid
      className={classes.background}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grow in={true} mountOnEnter unmountOnExit>
        <Card className={`${classes.card} ${(config.env === 'staging' ? classes.isStaging : null)}`}>
          <div style={{ height: 16 }}/>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item container justifyContent="space-around" alignItems="center">
                <img src="logo192.png" style={{maxWidth: '60px'}} alt="logo"/>
                <Grid item>
                <Typography variant="h4" component="h1">
                  Skills Lab
                </Typography>
                <Typography variant="h5">
                  360°-Feedback
                </Typography>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                title="Login via MedForGe"
                onClick={() => dispatch(userOperations.authenticateWithMedforge())}
              >
                Anmelden
              </Button>
              <Grid
                container
                direction="row"
                justifyContent={'space-evenly'}
                alignContent={'center'}
                className={classes.logos}
              >
                <Grid item>
                  <img
                    style={{ margin: 8 }}
                    width={115}
                    src={ShmLogo}
                    alt={''}
                  />
                </Grid>
                <Grid item>
                  <img
                    style={{ margin: 8 }}
                    width={115}
                    src={IfasLogo}
                    alt={''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grow>
      <QrCodeLogin/>
    </Grid>
  )
}

export default LoginForm
