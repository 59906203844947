import ClientOAuth2 from 'client-oauth2';

import config from '../../config';
import fetch from '../fetch';
import actions from './actions';


const oauthRedirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL
const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID
const medforgeUrl = process.env.REACT_APP_MEDFORGE_URL
const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const medforgeAuth = oauthRedirectUrl => {
  return new ClientOAuth2({
    clientId: oauthClientId,
    // TODO: we should use Auth Flow with PKCE when implemented in Passport
//   clientSecret: 'secret',
//   accessTokenUri: `${medforgeUrl}/oauth/token`,
    authorizationUri: `${medforgeUrl}/#!/oauth?client_name=360%C2%B0-Feedback`,
    redirectUri: oauthRedirectUrl,
    scopes: []
  })
}

/**
 * Redirect to OAuth authorization URI
 */
export const authenticateWithMedforge = () => dispatch => {
// TODO: we should use Auth Flow with PKCE when implemented in Passport
//  window.location = medforgeAuth.code.getUri()
  window.location = medforgeAuth(oauthRedirectUrl).token.getUri()
}

/**
 * Handles the redirect URI with an authorization_code and requests
 * an access_token.
 * @param {string} url OAuth redirect URI with code param
 */
const authenticateWithUrl = (url, authFlow) => dispatch => {

  if (!['token', 'code'].includes(authFlow)) {
    console.warn('Only code and token are valid OAuth flows')
    return
  }
  let accessToken
  dispatch(actions.signInRequest())
  const tokenFlow = authFlow === 'token' ? medforgeAuth(oauthRedirectUrl).token : medforgeAuth.code
  tokenFlow.getToken(url)
    .then(token => {
      accessToken = token.accessToken
      return getUserWithAccessToken(accessToken)
    })
    .then(user => dispatch(actions.signInSuccess(user)))
    .catch(err => dispatch(actions.signInFailure(err)))
}

const authenticateWithAccessToken = accessToken => dispatch => {
  dispatch(actions.signInRequest())
  getUserWithAccessToken(accessToken)
    .then(user => dispatch(actions.signInSuccess(user)))
    .catch(err => dispatch(actions.signInFailure(err)))
}

const getUserWithAccessToken = accessToken => {
  return fetch(`${medforgeUrl}/api/user`, { headers: { authorization: 'Bearer ' + accessToken } })
  .then(response => response.json())
  .then(({id, firstname, lastname, permissions, cadsId}) => {
    return {
      id: id.toString(),
      firstName: firstname,
      lastName: lastname,
      permissions,
      accessToken: accessToken,
      cadsId: cadsId?.toString(),
    }
  })
}

/**
 * Request sign out from current device. Given token will be revoked.
 */
export const signOut = (user) => dispatch => {

  localStorage.removeItem(`${config.localStoragePrefix}user`)
  const promise = user?.accessToken ?
    fetch(`${mongoUrl}/logout`, {
      method: 'POST',
      headers: { authorization: 'Bearer ' + user.accessToken }
    })
    .then(_ => fetch(`${medforgeUrl}/api/logout`, {
      method: 'POST',
      headers: { authorization: 'Bearer ' + user.accessToken }
    })) :
    Promise.resolve()
  promise.finally(_ => dispatch(actions.reset()))
}

const userOperations = {
  signOut,
  authenticateWithMedforge,
  authenticateWithUrl,
  authenticateWithAccessToken
}

export default userOperations