import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { find as findAccounts } from '../ducks/accounts/api';

function StudentAutocomplete({ onSelect }) {
  const user = useSelector(state => state.user.user)

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  const handleInputChange = (event, newInputValue) => setInputValue(newInputValue)
  const handleChange = (event, newValue) => {
    onSelect(newValue)
    setValue(null)
    setInputValue('')
  }

  const timer = useRef(null);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    if (inputValue === '') {
      setStudents([])
      setLoading(false)
      return
    }
    setLoading(true)
    timer.current = setTimeout(() =>
    findAccounts(user, inputValue)
      .then(accounts => setStudents(accounts))
      .then(_ => setLoading(false))
    , 1000)
  }, [user, inputValue])

  useEffect(() => {
    if (loading) {
      setStudents([])
    }
  }, [loading])

  return (
    <Autocomplete
      options={students}
      groupBy={(option) => option.semesterTitle}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value}
      loading={loading}
      onChange={handleChange}
      clearOnEscape
      style={{ width: 200 }}
      renderInput={(params) => <TextField
        {...params}
        label="Student:in hinzufügen"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
      }
    />
  )

}

export default StudentAutocomplete
