import types from './types';


const INITIAL_STATE = {
  user: null,
  error: '',
  intendedUrl: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SIGN_IN_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.SIGN_IN_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case types.SIGN_IN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case types.SIGN_OUT_REQUEST:
      return { ...state, loading: true }
    case types.SIGN_OUT_SUCCESS:
      return { ...state, ...INITIAL_STATE, loading: false }
    case types.SIGN_OUT_FAILURE:
      return { ...state, error: action.payload, loading: false }
    case types.RESET_ERROR:
      return { ...state, error: '' }
    case types.RESET_AUTHENTICATION:
      return { ...state, ...INITIAL_STATE }
    case types.SET_INTENDED_URL:
      return { ...state, intendedUrl: action.payload }
    default:
      return state
  }
}

export default reducer
