import { Button, Card, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { contextActions, courses, getCourseByTitle, getRoomByTitle, rooms } from '../ducks/context';
import { observationRole } from '../ducks/learnableMoments';
import GroupSelection from './GroupSelection';

const ResetButton = ({ handleReset, ...rest }) =>
  <Grid item>
    <Tooltip title="Auswahl zurücksetzen">
      <IconButton {...rest} size="small" onClick={() => handleReset(null)}><CancelIcon/></IconButton>
    </Tooltip>
  </Grid>

const useStyles = makeStyles(theme => ({
  background: {
    // height: '100%',
    // overflow: 'auto',
    // background: `linear-gradient(to right bottom, ${
    //   theme.palette.primary.main
    //   }, ${theme.palette.primary.light})`
  },
  card: {
    padding: '10px',
    marginBottom: '20px'
  }
}))

function ContextSelection() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const context = useSelector(state => state.context)
  const roomIsValid = context.room && rooms.find(r => r.id === context.room.id && r.roles.includes(context.role))
  const spRoomIsValid = context.spRoom && rooms.find(r => r.id === context.spRoom.id)

  const { setCourse, setRole, setRoom, setSpRoom, setGroup } = contextActions

  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (!search) {
      return
    }
    const searchParams = qs.parse(search, {ignoreQueryPrefix: true})
    const { course: courseTitle, role, room: roomTitle, spRoom: spRoomTitle } = searchParams
    if (!(courseTitle || role || roomTitle || spRoomTitle)) {
      return
    }
    handleCourseSelection((courseTitle && getCourseByTitle(courseTitle)) || context.course)
    handleRoleSelection([observationRole.TUTOR, observationRole.SP].includes(role) ? role : context.role)
    handleRoomSelection((roomTitle && getRoomByTitle(roomTitle)) || context.room)
    dispatch(setSpRoom((spRoomTitle && getRoomByTitle(spRoomTitle)) || context.spRoom))
    const cleanSearch = qs.stringify({...searchParams, course: undefined, role: undefined, room: undefined, spRoom: undefined})
    history.replace({pathname, search: cleanSearch })
    setRedirect(true)
  // eslint-disable-next-line
  }, [search])

  useEffect(() => {
    if (redirect && contextIsValid(context)) {
      redirectToContextSession()
    }
  // eslint-disable-next-line
  }, [redirect, context])

  function handleCourseSelection(course) {
    if (course === context.course) return
    dispatch(setCourse(course))
    dispatch(setGroup(null))
  }

  function handleRoleSelection(role) {
    if (role === context.role) return
    dispatch(setRole(role))
  }

  function handleRoomSelection(room) {
    if (room === context.room) return
    dispatch(setRoom(room))
    if (room && room.defaultSpRoomId) {
      const spRoom = rooms.find(r => r.id === room.defaultSpRoomId)
      dispatch(setSpRoom(spRoom))
    }
  }

  function handleSpRoomSelection(room) {
    if (room === context.spRoom) return
    dispatch(setSpRoom(room))
  }

  function handleGroupSelection(group) {
    if (group === context.group) return
    dispatch(setGroup(group))
  }

  function contextIsValid({course, room, role, group}) {
    return !!course
  }

  function redirectToContextSession() {
    if (context.role === observationRole.SP) {
      history.push('sp-session')
    } else {
      history.push('session')
    }
  }

  const courseList = courses
    .map(course =>
      <Grid item key={course.id}>
        <Button color="primary" variant={context.course && context.course.id === course.id ? 'contained' : 'outlined'} onClick={() => handleCourseSelection(course)}>{course.title}</Button>
      </Grid>
    )

  const roomList = rooms
    .filter(r => r.roles.includes(observationRole.TUTOR))
    .map(room =>
      <Grid item key={room.id}>
        <Button color="primary" variant={context.room && context.room.id === room.id ? 'contained' : 'outlined'} onClick={() => handleRoomSelection(room)}>{room.title}</Button>
      </Grid>
    )

  const spRoomList = rooms
    .filter(r => r.roles.includes(observationRole.SP))
    .map(room =>
      <Grid item key={room.id}>
        <Button color="primary" variant={context.spRoom && context.spRoom.id === room.id ? 'contained' : 'outlined'} onClick={() => handleSpRoomSelection(room)}>{room.title}</Button>
      </Grid>
    )
  return (
    <div className={classes.background}>
      <Typography variant="body1" paragraph>Bitte wählen Sie zum Starten den Kontext aus:</Typography>
      <Card className={classes.card}>
        <strong>Kurs:</strong><br/><br/>
        <Grid container spacing={2}>{ courseList }</Grid>
      </Card>
      <Card className={classes.card}>
        <strong>1. Ihre Rolle:</strong><br/><br/>
        <Grid container spacing={2} >
          <Grid item>
            <Button color="primary" variant={context.role && context.role === observationRole.TUTOR ? 'contained' : 'outlined'} onClick={() => handleRoleSelection(observationRole.TUTOR)}>Dozent:in/Tutor:in</Button>
          </Grid>
          <Grid item>
            <Button disabled color="primary" variant={context.role && context.role === observationRole.SP ? 'contained' : 'outlined'} onClick={() => handleRoleSelection(observationRole.SP)}>SP</Button>
          </Grid>
          { context.role && <ResetButton disabled handleReset={handleRoleSelection}/> }
        </Grid>
      </Card>
      { context.role ?
      <Card className={classes.card}>
        <strong><i>2a. Raum (Wo sitzen Sie?) (Optional):</i></strong><br/>
        <br/>
        <Grid container spacing={2}>
          { roomList }
          { roomIsValid && <ResetButton handleReset={handleRoomSelection}/> }
        </Grid>
      </Card> : null }
      { context.role ?
      <Card className={classes.card}>
        <strong><i>2b. SP-Raum (Wo findet die Szene statt) (Optional):</i></strong><br/>
        <br/>
        <Grid container spacing={2}>
          { spRoomList }
          { spRoomIsValid && <ResetButton handleReset={handleSpRoomSelection}/> }
        </Grid>
      </Card> : null }
      { context.role === observationRole.TUTOR ?
      <Card className={classes.card}>
      <strong>3. Gruppe wählen:</strong><br/><br/>
        <Grid container spacing={2}>
          <Grid item>
            <GroupSelection selectedGroup={context.group} onChangeSelection={group => handleGroupSelection(group)}/>
          </Grid>
          <Grid item>
            { context.group && <ResetButton handleReset={handleGroupSelection}/> }
          </Grid>
        </Grid>
      </Card>
      : null}
      { contextIsValid(context) ?
        <Button variant="contained" onClick={() => redirectToContextSession()}>Weiter</Button>
      : null}
    </div>
  )

}

export default ContextSelection
