const BASE_PATH = 'api/users/'

const types = {
  SIGN_IN_REQUEST: BASE_PATH + 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: BASE_PATH + 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: BASE_PATH + 'SIGN_IN_FAILURE',

  SIGN_OUT_REQUEST: BASE_PATH + 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: BASE_PATH + 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: BASE_PATH + 'SIGN_OUT_FAILURE',

  SET_INTENDED_URL: BASE_PATH + 'SET_INTENDED_URL',

  RESET_ERROR: BASE_PATH + 'RESET_ERROR',
  RESET_AUTHENTICATION: BASE_PATH + 'RESET_AUTHENTICATION'
}

export default types
