const defaultConfig = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric"
}

export default function Timestamp({ timestamp, config }) {
  config = {...defaultConfig, ...(config || {})}
  const dateTimeString = new Date(timestamp).toLocaleString(undefined, config)
  return dateTimeString
}
