import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { list as listLearnableMoments } from '../ducks/learnableMoments/api';
import LearnableMomentEntry from './LearnableMomentEntry';

function LearnableMomentList() {
  const user = useSelector(state => state.user.user)
  const [learnableMoments, setLearnableMoments] = useState(null)
  const queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => user.cadsId && listLearnableMoments(user, {student: {id: user.cadsId}, onlyFinished: true})
      .then(lms => lms.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)))
      .then(lms => setLearnableMoments(lms))
    , [user]
  )

  if (user && !user.cadsId) {
    return <>Ihrem Account ist keine Medicampus-Kennung (CADS-ID) zugeordnet. Es können keine Lernmomente angezeigt werden.</>
  }

  const expandedLms = queryParams.get('open')?.split(',') || [];

  return (
    <>
      <h4>Liste Ihrer Lernmomente</h4>
      <div>
      { !learnableMoments ?
        'Wird geladen...' :
        !learnableMoments.length ?
          'Keine Lernmomente vorhanden' :
          learnableMoments
          .map((lm) =>
            <LearnableMomentEntry key={lm.id} lm={lm} expanded={expandedLms.includes(lm.id)} own={true}/>
          )}
      </div>
    </>
  )

}

export default LearnableMomentList
