const env = process.env.REACT_APP_ENV || process.env.NODE_ENV

export const localStoragePrefix = `shm_sessions.${env}.`
export const reduxKey = `shm_sessions:${env}`
export const requiredPermissions = {
  role: ['shm-sessions'],
  student: ['myepas-read-own-records']
}

export const learnableMoment = {
  minutesBeforeClosingIsPossible: 5,
  minutesUntilFinishIsFixed: 1,
}

export const colors = {
  socialCompetencies: {
    agency: '#b3cddc',
    communion: '#c2c5c8',
    interpersonalResilience: '#d7e9b9',
  }
}

const config = {
  env,
  localStoragePrefix,
  reduxKey,
  requiredPermissions,
  learnableMoment,
}

export default config