import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  colors,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChatIcon from '@material-ui/icons/Chat';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Timestamp from '../commons/Timestamp';
import { courses } from '../ducks/context';
import { observationRole, observationState as observationStateEnum } from '../ducks/learnableMoments';
import {
  read as getLearnableMoment,
  getSubmissions,
  remove as removeLearnableMomentApi,
  update as updateLearnableMoment,
} from '../ducks/learnableMoments/api';
import Graph from './graphs/Graph';
import LearnableMomentListForStudent from './LearnableMomentListForStudent';
import Person from './Person';

const apiUrl = process.env.REACT_APP_BACKEND_URL

const useStyles = makeStyles(theme => ({
  accordion: {
    background: colors.grey[100]
  },
  infoAccordion: {
    background: 'rgb(232, 244, 253)'
  },
  infoIcon: {
    color: '#2196f3'
  },
  card: {
    background: colors.grey[100],
    padding: '10px',
    marginBottom: '20px',
    maxHeight: '500px',
    overflow: 'auto'
  },
  list: {
    flexDirection: 'column'
  }
}))

const prepareGraphItems = submissionData => Object.values(
  submissionData
    .map(submission => submission.items
      .filter(item => item.value !== null)
      .map(item => ({
        id: submission.id,
        name: item.name,
        role: submission.role,
        value: item.value,
      })))
    .reduce((arr, curr) => arr.concat(curr), [])
    .reduce((obj, curr) => {
      const key = `${curr.name}-${curr.role}`
      obj[key] = obj[key] || {
        ...curr,
        id: key,
        value: [],
      }
      obj[key].value.push(curr.value)
      return obj
    }, {})
  )

function LearnableMoment() {
  const classes = useStyles()
  const { id: learnableMomentId } = useParams()
  const history = useHistory()
  const user = useSelector(state => state.user.user)

  const [learnableMoment, setLearnableMoment] = useState(null)
  const [submissions, setSubmissions] = useState()
  const [error, setError] = useState(null)
  const [showGraph, setShowGraph] = useState(false)

  useEffect(() => learnableMomentId &&
    getLearnableMoment(user, learnableMomentId)
    .then(learnableMoment => setLearnableMoment(learnableMoment))
    .catch(error => setError(error.message || 'Unbekannter Fehler beim Abrufen des Lernmoments'))
  , [learnableMomentId, user]);

  useEffect(() => learnableMoment &&
    learnableMoment.observationState === observationStateEnum.FINISHED &&
    getSubmissions(user, learnableMoment.id)
    .then(submissions => setSubmissions(submissions))
    .catch(error => setError(error.message || 'Unbekannter Fehler beim Abrufen der Daten'))
  , [learnableMoment, user])

  // Reload every 5 seconds when the feedback phase started
  useEffect(() => learnableMoment &&
    learnableMoment.observationState === observationStateEnum.STARTED &&
    setTimeout(() =>
      getLearnableMoment(user, learnableMoment.id)
      .then(learnableMoment => setLearnableMoment(learnableMoment))
      .catch(error => setError(error.message || 'Unbekannter Fehler beim Abrufen der Daten')), 5000)
  , [learnableMoment, user])

  const startObservation = (nextStateUrl) => {
    updateLearnableMoment(user, {...learnableMoment, observationState: observationStateEnum.STARTED})
      .then(learnableMoment => setLearnableMoment(learnableMoment))
      .then(_ => nextStateUrl && window.open(nextStateUrl, '_blank'))
      .catch(error => setError(error.message || 'Unbekannter Fehler beim Aktualisieren'))
  }

  const cancelObservation = () => {
    updateLearnableMoment(user, {...learnableMoment, observationState: observationStateEnum.NOT_YET_STARTED})
      .then(learnableMoment => setLearnableMoment(learnableMoment))
      .catch(error => setError(error.message || 'Unbekannter Fehler beim Aktualisieren'))
  }

  const finishObservation = () => {
    updateLearnableMoment(user, {...learnableMoment, observationState: observationStateEnum.FINISHED})
      .then(learnableMoment => setLearnableMoment(learnableMoment))
      .then(_ => getSubmissions(user, learnableMoment.id))
      .then(submissions => setSubmissions(submissions))
      .catch(error => setError(error.message || 'Unbekannter Fehler'))
  }

  const removeLearnableMoment = () => {
    removeLearnableMomentApi(user, learnableMoment)
      .then(_ => setLearnableMoment(null))
      .then(_ => history.push('/session'))
      .catch(error => setError(error.message || 'Unbekannter Fehler beim Löschen'))
  }

  if (!learnableMoment && !error) {
    return ('Wird geladen...')
  }

  if (error) {
    return (error)
  }

  const { student, author, observationState, observations, updatedAt, context, assessmentItems } = learnableMoment
  const course = context?.type === 'course' && courses.find(c => c.name === context.name)

  const observationSlots = [
    {
      role: observationRole.GROUP,
      title: 'Qualitatives Feedback',
      qrCodeSize: 300,
    },
    {
      role: observationRole.SELF,
      title: 'Selbsteinschätzung',
      qrCodeSize: 300,
      open: true,
    },
    {
      role: observationRole.PEER,
      title: 'Fremdeinschätzung (Peers)',
      qrCodeSize: 300,
    },
    {
      role: observationRole.TUTOR,
      title: 'Fremdeinschätzung (Tutor:in)',
      qrCodeSize: 300,
    }
  ]

  const validObservationSlots = observationSlots
    .map(slot => ({...slot, observation: observations.find(observation => observation.role === slot.role)}))
    .filter(slot => !!slot.observation?.token)

  const qrCodes = <div>
    {
      validObservationSlots.map(slot => <Accordion key={slot.role} className={classes.accordion} defaultExpanded={!!slot.open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`qr-code-${slot.role}-header`}
        >
          <Typography className={classes.heading}>Feedbackbogen für <strong>{slot.title}</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <QRCode size={slot.qrCodeSize} value={`${apiUrl}/observations/${slot.observation.token}`}/><br/>
            <Link target="_blank" href={`${apiUrl}/observations/${slot.observation.token}`}>Feedbackbogen öffnen</Link>
          </Grid>
        </AccordionDetails>
      </Accordion>
      )
    }
  </div>

  const previousLearnableMoments =
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`previous-learnable-moments-header`}
      >
        <Typography>Vergange Lernmomente</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.list}>
        <LearnableMomentListForStudent student={student} course={course}/>
      </AccordionDetails>
    </Accordion>

  const tutorSlot = validObservationSlots.find(slot => slot.role === observationRole.TUTOR)
  const nextStateUrl = tutorSlot && `${apiUrl}/observations/${tutorSlot.observation.token}`

  let stateView;
  switch (observationState) {
    case observationStateEnum.NOT_YET_STARTED:
      let nextStateButton = <Button variant="contained" color="primary" onClick={() => startObservation(nextStateUrl)}><ChatIcon/>&nbsp;Feedbackphase starten</Button>
      if (nextStateUrl) {
        nextStateButton = <Tooltip title="Fremdeinschätzung wird automatisch geöffnet">
          {nextStateButton}
        </Tooltip>
      }
      stateView = <>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <Accordion className={classes.infoAccordion}>
              <AccordionSummary classes={{expandIcon: classes.infoIcon}} expandIcon={<InfoOutlinedIcon />}>
                <Typography>Schritt 1/3: Simulationsphase</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Die Szene kann jetzt beginnen. Nach der Szene kann die Feedbackphase gestartet werden. { nextStateUrl && 'Der Feedbackbogen für die Fremdeinschätzung wird anschließend automatisch geöffnet.' }
              </AccordionDetails>
            </Accordion>
            { qrCodes }
            { previousLearnableMoments }
          </Grid>
        </Grid>
        <br/>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => removeLearnableMoment()}><DeleteIcon/>&nbsp;Lernmoment löschen</Button>
          </Grid>
          <Grid item>{ nextStateButton }</Grid>
        </Grid>
      </>
      break;
    case observationStateEnum.STARTED:
      const hasFinishedObservations = !!observations.filter(o => o.state === observationStateEnum.FINISHED).length
      stateView = <>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          <Grid item>
            <Accordion className={classes.infoAccordion}>
              <AccordionSummary classes={{expandIcon: classes.infoIcon}} expandIcon={<InfoOutlinedIcon />}>
                <Typography>Schritt 2/3: Feedbackphase</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Die Szene ist abgeschlossen. Feedback kann nun abgegeben werden.
              </AccordionDetails>
            </Accordion>
            { qrCodes }
            { previousLearnableMoments }
          </Grid>
        </Grid>
        <br/>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => cancelObservation()} disabled={hasFinishedObservations}><ArrowBackIosIcon/>&nbsp;Feedbackphase abbrechen</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => finishObservation()}><DoneIcon/>&nbsp;Feedbackphase abschließen</Button>
          </Grid>
        </Grid>
      </>
      break;
    case observationStateEnum.FINISHED:
      stateView = <>
        <p><u>Überblick über abgegebenes Feedback:</u><br/><small>Es werden nur Rollen und Dimensionen angezeigt, für die mindestens ein Wert vorhanden ist.</small></p>
        { submissions ?
          submissions.length ?
            showGraph ?
              <Graph items={prepareGraphItems(submissions)} context={{...context, assessmentItems}}/> :
              <Button variant="outlined" color="primary" onClick={() => setShowGraph(true)}><VisibilityIcon/>&nbsp;Zusammenfassung einblenden</Button> :
            <Alert severity="warning">Es wurden keine Feedback-Daten für diesen Lernmoment gefunden.</Alert> :
          'Daten werden geladen ...'
        }
      </>
      break;
    default:
      break;
  }

  return (
    <div>
      <Typography variant="h5">
        Lernmoment von <strong><Person person={student}/></strong>
      </Typography>
      <Typography variant="subtitle1">
        Kurs: <strong>{ learnableMoment.context?.title || 'Nicht gesetzt' }</strong>
      </Typography>
      { learnableMoment.room &&
        <Typography variant="subtitle1">
          SP-Raum: <strong>{ learnableMoment.room.title }</strong>
        </Typography>
      }
      <Typography variant="caption" color="textSecondary">
        Erstellt von <strong><Person person={author}/></strong>, zuletzt bearbeitet am <strong><Timestamp timestamp={updatedAt}/></strong>
      </Typography>
      <br/>
      { stateView }
    </div>
  )
}

export default LearnableMoment
