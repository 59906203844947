import { Paper, makeStyles } from '@material-ui/core';
import React from 'react';

import AppBar from '../../commons/AppBar';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  paper: {
    maxWidth: '800px',
    padding: '30px',
    margin: '20px auto',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 15px 30px 15px',
      margin: '0 auto',
      borderRadius: '0px'
    }
  }
}))

export default function WrapperWithBar({ title, children }) {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <AppBar title={title}/>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </div>
  )
}
