import { colors, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8em',
    color: '#fff',
    fontWeight: 'bold'
  }
}));

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
})(Tooltip);

const SquareGraphItem = ({ value, color, min, max, basis }) => {
  const classes = useStyles()
  if (value === null) return <span style={{color: colors.grey[400]}}>✕</span>
  value = +value
  const itemSize = [10, basis]
  const relVal = (value - min) / (max - min)
  const size = itemSize[0] + ((itemSize[1] - itemSize[0]) * relVal)

  return (
    <StyledTooltip title={value} arrow placement="bottom">
      <div className={classes.root} style={{
        width: size,
        height: size,
        background: color,
      }}>
        <div>{ value > 0 && value }</div>
      </div>
    </StyledTooltip>
  )
}

export default SquareGraphItem