import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ScanQrCodeButton from '../commons/ScanQrCodeButton';
import { findByQrCode as findAccountByQrCode } from '../ducks/accounts/api';
import { list as listLearnableMoments } from '../ducks/learnableMoments/api';
import LearnableMomentEntry from './LearnableMomentEntry';

export default function LearnableMomentListForStudent ({student, course}) {
  const user = useSelector(state => state.user.user)
  const [learnableMoments, setLearnableMoments] = useState(null)
  const [needScan, setNeedScan] = useState(null)
  const [error, setError] = useState(null)

  const contextCourse = useSelector(state => state.context.course)
  course = course || contextCourse

  useEffect(() => {
    if (needScan) {
      return;
    }
    setError(null)
    listLearnableMoments(user, {student, onlyFinished: true, onlyIfScanned: true})
      .then(lms => lms.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)))
      .then(lms => setLearnableMoments(lms))
      .catch(error => {
        setLearnableMoments([])
        if (`${error}` === 'Error: Student not scanned') {
          setNeedScan(true)
          setError('Student:in muss erneut gescannt werden, um Lernmomente anzuzeigen.')
        } else {
          setError('Fehler beim Abrufen der Lernmomente.')
        }
      })
  }, [user, student, needScan])

  const handleScanSuccess = qrCode => {
    findAccountByQrCode(user, qrCode, course.externalIds)
      .then(participant => {
        if (participant.id === student.id) {
          setNeedScan(false)
        } else {
          setError(`Falscher QR-Code für ${student.firstName} ${student.lastName} gescannt.`)
        }
      })
      .catch(error => {
        if (`${error}` === 'Error: No participant in provided course ids with this qrcode') {
          error = 'Ungültiger QR-Code oder Student:in nicht für Veranstaltung gemeldet.'
        }
        setError(`${error}`)
      })
  }

  if (!learnableMoments) {
    return <Typography variant="caption">wird geladen...</Typography>
  }

  if (error) {
    return <Grid container direction="column" alignItems='flex-start'>
        <Typography variant="caption">{error}</Typography>
        { needScan && <ScanQrCodeButton onSuccess={handleScanSuccess} onError={error => setError(error)}/>}
      </Grid>
  }

  if (!learnableMoments.length) {
    return 'Keine vergangenen Lernmomente gefunden.'
  }
  return <>
      {learnableMoments
        .map((lm) =>
        <LearnableMomentEntry key={lm.id} lm={lm}/>
      )}
    </>
}