import { Button, Card, Link, Paper, makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { addMinimalPermission } from '../../ducks/auth/api';
import { userOperations } from '../../ducks/users';
import { requiredPermissions } from '../../config';
import Person from '../Person';


const useStyles = makeStyles(theme => ({
  background: {
    height: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`,
    position: 'absolute',
    left: '0px',
    width: '100%',
  },
  card: {
    overflow: 'auto',
    maxWidth: '800px',
  },
  cardContent: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  disclaimerBox: {
    padding: '20px',
    background: '#eee',
    marginBottom: '20px',
  }
}))

const AuthError = () => {
  const user = useSelector(state => state.user.user)
  const intendedUrl = useSelector(state => state.user.intendedUrl)
  console.log(user)
  const dispatch = useDispatch()
  const classes = useStyles()
  const handleSignOut = () => {
    dispatch(userOperations.signOut())
  }

  const handleDisclaimerAcceptance = () => {
    addMinimalPermission(user).then(() => handleSignOut())
  }

  if (!user) {
    return <Redirect to="./login"/>;
  }

  return (
    <Grid
      className={classes.background}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="column">
            <Typography variant="h4" component="h1" gutterBottom>360°-Feedback im SHM</Typography>
            <Typography variant="body1" paragraph>
              Sie haben keinen Zugriff auf die von Ihnen angeforderte Seite{intendedUrl ? ` (${intendedUrl.pathname}).` : '.'}
            </Typography>
            <Typography variant="h6" component="h1" gutterBottom>Sie sind Student:in?</Typography>
            {
              user.permissions.includes(requiredPermissions.student[0]) ?
              <Typography variant="body1" paragraph>
                Ihre eigenen Lernmomente können Sie <Link href='own-learnable-moments'>hier</Link> einsehen.
              </Typography> :
              <>
                <Typography variant="body1" paragraph>
                  Um <strong>Ihre eigenen Lernmomente</strong> einzusehen, lesen Sie bitte folgende Information und nehmen diese anschließend zur Kenntnis. Danach erhalten Sie automatisch das entsprechende Recht und müssen sich <strong>neu einloggen</strong>.
                </Typography>
                <Paper className={classes.disclaimerBox}>
                  <Typography variant="body1" paragraph>
                    Ihre abgeschlossen Lernmomente werden in einer Liste angezeigt. Durch einen Klick auf den Eintrag können Sie Details zu Ihrem Lernmoment einsehen. Jeder Eintrag enthält qualitatives und quantitatives Feedback zu sozialen Kompetenzen und relevanten (nested) EPAs.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Bitte scheuen Sie sich nicht, bei inhaltlichen Rückfragen oder sonstigem Beratungsbedarf, Hendrik Ohlenburg (ohlenburg@uni-muenster.de) vom IfAS zu kontaktieren.</strong>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Bei technischen Fragen hilft Ihnen Friedrich Pawelka (pawelka@uni-muenster.de) vom IfAS weiter.
                  </Typography>
                </Paper>
                <Button variant="contained" color="primary" onClick={() => handleDisclaimerAcceptance()} className={classes.disclaimerButton}>
                  Ich habe die Informationen zum 360°-Feedback verstanden und auch, an wen ich mich bei Fragen wenden kann.<br/>Bitte das Recht zum Aufrufen der Lernmomente erteilen.
                </Button>
              </>
            }
            <br/>
            <Typography variant="h6" component="h1" gutterBottom>Sie sind Tutor:in?</Typography>
            <Typography variant="body1" paragraph>
              Falls Sie Dozent:in oder Tutor:in sind und <strong>Lernmomente erstellen</strong> wollen, benötigen Sie ein <strong>anderes Recht</strong>. Dieses sollten Sie in Ihrer Rolle als Tutor:in im Rahmen der Veranstaltung erhalten.
            </Typography>
            <Button variant="outlined" color="primary" onClick={() => handleSignOut()}>Abmelden</Button>
            <Typography variant="body1">
              <i>Angemeldet als <strong><Person person={user}/></strong></i>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
)}

export default AuthError
