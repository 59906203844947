import 'moment/locale/de';

import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { requiredPermissions } from '../../config';
import LoginForm from '../auth';
import AuthenticateLoginToken from '../auth/AuthenticateLoginToken';
import AuthError from '../auth/AuthError';
import NotFound from '../auth/NotFound';
import RequireAuth from '../auth/RequireAuth';
import ContextSelection from '../ContextSelection';
import LearnableMoment from '../LearnableMoment';
import LearnableMomentList from '../LearnableMomentList';
import LearnableMomentListOwn from '../LearnableMomentListOwn';
import Session from '../Session';
import SpSession from '../SpSession';
import WrapperWithBar from './WrapperWithBar';


const App = () => {

  const loading = useSelector(state => state.user.loading)
  const course = useSelector(state => state.context.course)
  const courseTitle = course && ` (Kurs: ${course.title})`

  return loading ? <LinearProgress color={'secondary'}/> :
    <Switch>
      <Route exact path="/"><Redirect to="/login"/></Route>
      <Route exact path="/login" component={LoginForm}/>
      <Route exact path="/context-selection">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title="Kontextwahl">
            <ContextSelection/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/session">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title={`Seminar${courseTitle}`}>
            <Session/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/sp-session">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title={`SP-Warteraum${courseTitle}`}>
            <SpSession/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/learnable-moments">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title="Übersicht über Lernmomente">
            <LearnableMomentList/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/own-learnable-moments">
        <RequireAuth permissions={requiredPermissions.student}>
          <WrapperWithBar title="Übersicht über eigene Lernmomente">
            <LearnableMomentListOwn/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/learnable-moments/:id">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title="Lernmoment">
            <LearnableMoment/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/authenticate-token/:token">
        <RequireAuth permissions={requiredPermissions.role}>
          <WrapperWithBar title="Externes Gerät anmelden">
            <AuthenticateLoginToken/>
          </WrapperWithBar>
        </RequireAuth>
      </Route>
      <Route exact path="/403" component={AuthError}/>
      <Route component={NotFound}/>
    </Switch>
}

export default App
