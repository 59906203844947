import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import context from './context';
import user from './users';

const createRootReducer = history => combineReducers({
  user,
  context,
  router: connectRouter(history),
})

export default createRootReducer
