import { Card, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CameraIcon from '@material-ui/icons/Camera';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import QrCodeScanner from '../commons/QrCodeScanner';
import ScanQrCodeButton from '../commons/ScanQrCodeButton';
import { findByQrCode as findAccountByQrCode } from '../ducks/accounts/api';
import { addStudent, removeStudent, setGroup, setSpRoom } from '../ducks/context/actions';
import { observationRole } from '../ducks/learnableMoments';
import {
  countByPropertyAndIds as countLearnableMomentsForPersons,
  list as listLearnableMoments,
} from '../ducks/learnableMoments/api';
import { init as initLearnableMoment } from '../ducks/learnableMoments/operations';
import GroupSelection from './GroupSelection';
import Person from './Person';
import RoomSelect from './RoomSelect';
import StudentAutocomplete from './StudentAutocomplete';
import StudentOverview from './StudentOverview';

// import SessionWarning from './SessionWarning';
const useStyles = makeStyles(theme => ({
  card: {
    padding: '10px',
    marginBottom: '20px'
  },
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
    marginTop: 0,
  },
  listItemTitle: {
    marginTop: '3px',
    padding: '5px'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      rowGap: '.5rem'
    },
  }
}))

const ScanStudentQrCode = ({student, onError}) => {
  const user = useSelector(state => state.user.user)
  const course = useSelector(state => state.context.course)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const handleClose = result => {
    setOpen(false);
    if (result) {
      if (result.error) {
        onError(result.error)
      }
      if (result.qrCode) {
        return findAccountByQrCode(user, result.qrCode, course.externalIds)
          .then(participant => {
            if (participant.id === student.id) {
              dispatch(addStudent(participant))
            } else {
              onError(`Falscher QR-Code für ${student.firstName} ${student.lastName} gescannt.`)
            }
          })
          .catch(error => onError(error))
      }
    }
  }

  return (
    <>
      <Tooltip title="QR-Code scannen, um abgeschlossene Lernmomente anzuzeigen">
        <IconButton size="small" color="primary" onClick={() => setOpen(true)}><CameraIcon/></IconButton>
      </Tooltip>
      <QrCodeScanner open={open} onClose={handleClose}/>
    </>
  )

}

function Session() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const room = useSelector(state => state.context.room)
  const course = useSelector(state => state.context.course)
  const group = useSelector(state => state.context.group)
  const spRoom = useSelector(state => state.context.spRoom)
  const user = useSelector(state => state.user.user)

  const [activeLearnableMoments, setActiveLearnableMoments] = useState(null);
  const [learnableMomentsPerStudent, setLearnableMomentsPerStudent] = useState(null)
  const [error, setError] = useState(null)

  const students = group?.children

  useEffect(() => {
    listLearnableMoments(user, {onlyActive: true, withStubs: true})
      .then(lms => setActiveLearnableMoments(lms))
  }, [user])

  useEffect(() => {
    if (!students) {
      return;
    }
    countLearnableMomentsForPersons(user, 'student', students.map(student => student.id))
      .then(counts => setLearnableMomentsPerStudent(counts.reduce((obj, curr) => ({...obj, [curr._id]: curr.count}), {})))
      .catch(() => setLearnableMomentsPerStudent({}))
  }, [students, user])

  if (!group) {
    return (
      <div>
        <Typography variant="h5">Seminar</Typography>
        <GroupSelection selectedGroup={group} onChangeSelection={group => handleGroupSelection(group)}/>
      </div>
    )
  }

  const defaultObserverRoles = [
    observationRole.TUTOR,
    // observationRole.PEER,
    observationRole.SELF,
    // observationRole.SP,
    // observationRole.GROUP,
  ]

  const handleSpRoomSelect = room => dispatch(setSpRoom(room))
  function handleGroupSelection(changedGroup) {
    if (changedGroup === group) return
    dispatch(setGroup(changedGroup))
  }
  const handleAddStudent = student => dispatch(addStudent(student))
  const handleRemoveStudent = student => dispatch(removeStudent(student))
  const initLearnableMomentForStudent = student => initLearnableMoment(student, spRoom, user, course, defaultObserverRoles)
      .then(learnableMoment => history.push(`learnable-moments/${learnableMoment.id}`))
      .catch(error => {
        setError(error)
        setTimeout(() => setError(null), 10000)
      })

  const handleError = error => {
    if (`${error}` === 'Error: No participant in provided course ids with this qrcode') {
      error = 'Ungültiger QR-Code oder Student:in nicht für Veranstaltung gemeldet.'
    }
    setError(error)
    setTimeout(() => setError(null), 10000)
  }

  const addStudentByQrCode = qrCode => findAccountByQrCode(user, qrCode, course.externalIds)
    .then(participant => dispatch(addStudent(participant)))
    .catch(error => handleError(error))

  const actionButtonsForStudent = (student, activeLearnableMoments) => {
    const actions = [];
    actions.push(
      <Tooltip key="new" title="Neuen Lernmoment starten">
        <IconButton size="small" color="primary" onClick={() => initLearnableMomentForStudent(student)}><PlayArrowIcon/></IconButton>
      </Tooltip>
    )
    const activeLearnableMomentsFromStudent = (activeLearnableMoments || []).filter(lm => lm.student.id === student.id)
    if (activeLearnableMomentsFromStudent.length) {
      actions.push(...activeLearnableMomentsFromStudent.map(lm => {
        if (!lm.isStub) {
          return (
            <Tooltip key={lm.id} title={`Lernmoment öffnen (${lm.room?.title || 'Kein Raum'})`}>
              <IconButton size="small" color="primary" href={`learnable-moments/${lm.id}`}><AssignmentIndIcon/></IconButton>
            </Tooltip>
          )
        }
        return (
          <Tooltip key={lm.id} title={<>Lernmoment ({lm.room?.title || 'Kein Raum'}), Kann nur von <Person person={lm.author}/> geöffnet werden</>}>
            <IconButton size="small" color="primary" disabled styles={{cursor: 'not-allowed'}}><AssignmentIndIcon/></IconButton>
          </Tooltip>
        )
      }))
    }
    return actions;
  }

  const overviewButtonForStudent = student => {
    if (!student.scannedByUser) {
      return <ScanStudentQrCode student={student} onError={handleError}/>
    }

    return <StudentOverview student={student}/>
  }

  return (
    <div>
      <Typography variant="h5">Seminar</Typography>
      <p>
        Sie betreuen als Dozent:in/Tutor:in <strong><Person person={user}/></strong> im Kurs <strong>{ course?.title }</strong> folgende Seminargruppe<strong>{ group?.title }</strong>{room && <> im <strong>{ room.title }</strong></>}.
      </p>
      { spRoom && <>
        <p>Die Simulationspatientin bzw. der Simulationspatient befindet sich in folgendem Raum:</p>
        <RoomSelect style={{marginBottom: '20px'}} selectedRoom={spRoom} onRoomSelect={handleSpRoomSelect} filterByRole={observationRole.SP} title="SP-Raum"/>
        </>
      }
      { error ? <Alert style={{marginBottom: '20px'}} severity="error" onClose={() => setError(null)}>
        <AlertTitle><b>Es ist ein Fehler aufgetreten</b></AlertTitle>
        Details: { error.toString() }
      </Alert> : null}
      {/* <SessionWarning style={{marginBottom: '20px'}} spRoom={spRoom} activeLearnableMoments={activeLearnableMoments}/> */}
      <Card className={classes.card}>
        {/* <p><i>Auf "Play" klicken, um einen Lernmoment{spRoom && <> für den SP-Raum <strong>{ spRoom.title }</strong></>} zu erstellen.</i></p> */}
        <ul className={classes.list}>
          {students.map(student =>
            <li key={student.id}>
              <Tooltip title="Student:in aus Gruppe entfernen">
                <IconButton size="small" onClick={() => handleRemoveStudent(student)}><CloseIcon fontSize="small"/></IconButton>
              </Tooltip>
              <Person person={student} className={classes.listItemTitle}/>&nbsp;
              { learnableMomentsPerStudent ? <small>(Lernmomente: { learnableMomentsPerStudent[student.id] || 0 })</small> : null }&nbsp;
              { actionButtonsForStudent(student, activeLearnableMoments) }
              { overviewButtonForStudent(student) }
            </li>
          )}
        </ul>
        <div className={classes.buttons}>
          <StudentAutocomplete onSelect={handleAddStudent}/>
          <span>oder</span>
          <ScanQrCodeButton onSuccess={addStudentByQrCode} onError={handleError} />
          { group.id === 'null' && <>
            <span>oder</span>
            <GroupSelection selectedGroup={group} onChangeSelection={group => handleGroupSelection(group)} customText='Gruppe wählen'/>
          </>}
        </div>
      </Card>
      <Alert style={{ marginTop: '20px', fontSize: '0.7rem' }} severity="success">
        <AlertTitle style={{ fontSize: '0.7rem' }}><b>Datenschutzhinweis</b></AlertTitle>
        <p>Die während des "Lernmoments" erfassten Daten sind nur für die/den betreffende:n Student:in in der persönlichen Übersicht verfügbar.</p>
      </Alert>
    </div>
  )

}

export default Session
