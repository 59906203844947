import fetch from '../fetch';

const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const index = (user) => {
  const url = `${mongoUrl}/accounts`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const find = (user, name) => {
  const url = `${mongoUrl}/accounts?name=${name}`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

export const findByQrCode = (user, qrCode, externalCourseIds) => {
  const url = `${mongoUrl}/accounts/by-qrcode/${qrCode}?courseIds=${externalCourseIds.join(',')}`
  return  fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  if (!response.ok) throw new Error(response.statusText)
  return response.json()
}