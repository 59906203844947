import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import Logo from '../assets/images/logo192.png';
import Person from '../containers/Person';
import { observationRole } from '../ducks/learnableMoments';
import { userOperations } from '../ducks/users';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    height: '64px'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    paddingLeft: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  sectionDesktop: {
    display: 'flex'
  },
  user: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4)
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1)
  },
  thinHeader: {
    lineHeight: '1',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  lightText: {
    color: '#e0f2f194',
  },
  lightBackground: {
    backgroundColor: '#e0f2f194',
  },
  denseIcon: {
    minWidth: '35px',
  },
  logo: {
    width: '30px',
    marginRight: '.5rem',
  }
}))

const PrimarySearchAppBar = ({ title }) => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const role = useSelector(state => state.context.role)
  const group = useSelector(state => state.context.group)
  const user = useSelector(state => state.user.user)

  const [anchorEl, setAnchorEl] = useState(null)

  const isMenuOpen = Boolean(anchorEl)

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  function handleSignOut() {
    handleMenuClose()
    dispatch(userOperations.signOut(user))
  }

  const menuItems = []
  if (user.permissions.includes('shm-sessions')) {
    menuItems.push({
      title: 'Kontextauswahl',
      route: '/context-selection',
      icon: HomeIcon,
      order: 1
    },{
      title: 'Lernmomente',
      route: '/learnable-moments',
      icon: ListIcon,
      order: 3,
    })
  }
  if (role === observationRole.TUTOR && group) {
    menuItems.push({
      title: 'Seminar',
      route: '/session',
      icon: GroupIcon,
      order: 2
    })
  }

  const menuId = 'primary-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid item className={classes.user}>
        <Grid container direction={'column'} alignItems={'center'}>
          <Avatar className={classes.avatar} alt="No Image">
            {(user.firstName || 'N').charAt(0) + (user.lastname || 'A').charAt(0)}
          </Avatar>
          <Typography variant={'caption'}><Person person={user}/></Typography>
        </Grid>
      </Grid>
      <Divider light={true}/>
      { menuItems
        .sort((a, b) => a.order - b.order)
        .map(({title, route, icon: Icon}) =>
          <MenuItem key={title} onClick={handleMenuClose} component={RouterLink} to={route} selected={pathname === route} disabled={pathname === route}>
            <ListItemIcon className={classes.denseIcon}>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </MenuItem>
       )
      }
      <Divider light={true}/>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon className={classes.denseIcon}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Abmelden" />
      </MenuItem>
    </Menu>
  )
  return (
    <AppBar className={classes.root} position="sticky">
      <Toolbar className={classes.grow}>
        <div>
          <Grid container justifyContent="center" alignItems="center">
            <img src={Logo} className={classes.logo} alt="Logo"/>
            <Typography variant="h6" noWrap className={classes.thinHeader}>360°-Feedback</Typography>
          </Grid>
        </div>
        { title ?
          <>
          <Divider orientation="vertical" variant="middle" className={classes.lightBackground}></Divider>
          <Typography variant="subtitle1" noWrap>{title}</Typography>
          </>
          : null
        }
        <div className={classes.grow}/>
        <div className={classes.sectionDesktop}>
          <IconButton
            aria-label="Show more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
          >
            <MenuIcon/>
          </IconButton>
        </div>
      </Toolbar>
      {renderMenu}
    </AppBar>
  )
}

export default PrimarySearchAppBar
