import { conceptualFrameworkItems } from '../context';
import { contextType, observationRole } from './';
import { create } from './api';

export const init = (student, room, user, course, observerRoles) => {
  const learnableMomentContext = {
    ...course,
    type: contextType.COURSE,
  }

  const learnableMomentAssessmentItems = conceptualFrameworkItems
    .filter(item => (course.assessmentItems || []).includes(item.id))

  const userData = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  }

  const learnableMomentData = {
    student,
    author: userData,
    room,
    context: learnableMomentContext,
    assessmentItems: learnableMomentAssessmentItems,
    observerRoles: observerRoles.map(role => ({
      role, observer: [ role === observationRole.TUTOR ? userData : role === observationRole.SELF ? student : null ]
    }))
  }

  return create(user, learnableMomentData)
}

const operations = {
  init,
}

export default operations